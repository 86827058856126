@import '../../../styles/main';

.brand-lincoln {
    .service-history-section {
        .service-history-section-header,
        .service-history-format,
        .service-history-title h2,
        .service-history-panel-item-inner .service-history-item-header,
        .service-history-panel-item-inner .service-history-item {
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
        }
        .service-history-title h2 {
            font-size: 32px;
            letter-spacing: 2px;

            @include mobile {
                font-size: 24px;
            }
        }
        .service-history-limited-view-format a,
        .service-history-download-button-format a {
            font-family: $proxima-nova-semi-bold;
            text-decoration: none;
            color: $brand-secondary;
        }
        .service-history-limited-view-icon {
            content: url('../../../assets/warning-icon-lincoln.svg');
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
        .service-history-download-icon {
            content: url('../../../assets/download-icon-lincoln.svg');
        }
    }

    .odometer-info-icon {
        content: url('../../../assets/info-red-icon.svg');
    }

    .service-history-vehicle-details
        .service-history-vehicle-details-info
        .service-history-odometer,
    .service-history-vehicle-details
        .service-history-vehicle-details-info
        .service-history-odometer-label,
    .service-history-vehicle-details h2,
    .service-history-records-section p,
    .service-history-not-available p,
    .next-service-due-section p {
        font-family: $proxima-nova-regular;
        color: $brand-secondary;
    }

    .service-history-records-section h1,
    .service-history-not-available h1 {
        font-family: $lincoln-millerb-black;
        letter-spacing: normal;
        color: $brand-black;
    }

    .service-history-vehicle-details {
        box-shadow: none;
        border: 1px solid $brand-gray3;
        background: $brand-gray1;

        .service-history-vehicle-title-right {
            padding: 10px 0 0 0;
        }
    }
}
@import './service-history-accordion/service-history-accordion.lincoln.scss';
