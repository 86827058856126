@import '../../../styles/main.scss';
.spc-top-banner {
    position: sticky;
    top: 0;
    z-index: 999 !important;
    .osb-banner {
        height: 68px;
        @include mobile {
            width: 100%;
            height: 60px;
        }
        .leftSide {
            background-color: #102b4e;
            @include desktop {
                width: 100%;
            }
            @include mobile {
                padding: 0;
                text-align: center;
            }
            span {
                font-weight: normal;
            }
        }
    }
}
.spc-wrapper-api-error {
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    color: #721c24;
    padding: 10px;
    margin-bottom: 10px;
}

.spc-vehicle-container {
    width: 100%;
    height: 80px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mobile {
        height: auto;
    }

    .spc-vehicle-image-container {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        @include mobile {
            display: none;
        }
        .spc-vehicle-image {
            width: 200px;
            height: 200px;
            object-fit: contain;
            margin-bottom: 75px;
        }
    }
    .spc-vehicle-info-container {
        display: inline-block;
        vertical-align: middle;
        @include mobile {
            padding: 20px 0;
            display: flex;
            flex-direction: column;
        }
        .spc-left-span {
            color: #767676;
            font-family: $f1-font-regular;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 2px;
            line-height: 30px;
            @include mobile {
                text-align: center;
            }
        }

        .spc-right-span {
            color: #00095b;
            font-family: $f1-font-regular;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 2px;
            line-height: 32px;
            text-align: center;
        }
    }
}
