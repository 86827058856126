@import '../../../styles/main.scss';
.service-history-section {
    margin: 3.5% 7% 1.5% 7%;
    @include mobile {
        width: 100%;
        margin: 13% 0 0 0;
    }

    .back-to-ford-account-link {
        margin: auto 5% 30px 0;
        @include mobile {
            margin: 0 0 40px 20px;
        }
    }
}

.service-history-records-section {
    margin: 0 7% 7% 7%;
    @include mobile {
        width: 100%;
        margin: 10px 0 7% 0;
    }

    .modal {
        position: absolute !important;
        left: 20% !important;
        top: 70% !important;
        @include mobile {
            left: 0% !important;
            top: 42% !important;
            //margin-left: 4%;
        }
    }
}

.service-history-section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 0.3 1;

    @include mobile {
        flex-direction: column;
    }
}

.service-history-format {
    display: flex;

    @include mobile {
        display: grid;
    }
}

.service-history-title-right {
    justify-content: flex-end;
    flex-direction: row;
    display: flex;

    @include mobile {
        width: 100%;
        margin-top: 0;
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        margin-bottom: 20px;

        .service-history-add-button {
            display: grid;
            place-content: center;
            width: fit-content;
        }
    }
}

.service-history-add-button {
    line-height: unset;

    @include mobile {
        width: 60%;
        margin-top: 20px;
    }

    &-clicked {
        background-color: $fds-color-disabled-dark;
    }
    &:focus {
        outline: 2px solid $black !important;
    }
    .button-label {
        margin: 0 0 0 10px;
    }
}

.service-history-download-button {
    margin-top: 15%;
    margin-right: 50%;

    @include mobile {
        margin-top: 30%;
        margin-right: 0;
    }
}

div.tabs {
    border-bottom: none;

    @include mobile {
        min-width: 100vh;
        padding-left: 23px;
    }

    .tabs-nav {
        @include mobile {
            justify-content: flex-start;
            transition: transform 0.5s ease-in-out;
        }
        @include mobile-landscape {
            justify-content: center;
        }
    }

    .tab-container {
        padding-top: 19px;
    }

    button.tab {
        width: auto;
        font-size: 16px;
        line-height: 12px;

        &:hover {
            @include desktop {
                cursor: pointer;
                transition: transform 0.3s ease-in-out;
            }
        }

        @include mobile {
            margin-right: 0;
            margin-left: 0;
            max-width: 187px;
            box-sizing: border-box;
        }
    }
}

.service-history-accordion {
    @include mobile {
        max-width: 100%;
        overflow-x: hidden;
    }

    .accordion-section {
        border-top: 2px solid $fds-color-lt-gray;
        box-sizing: border-box;

        @include mobile {
            background-color: $fds-color-white;
        }

        button[aria-expanded='true'] {
            box-shadow: 0 12px 10px 5px rgba(0, 0, 0, 0.12);
            z-index: 10;
            position: relative;
        }

        .accordion-title {
            border-top: none;
            justify-content: flex-start;

            @include mobile {
                flex-wrap: wrap;
            }

            div {
                display: flex;
                align-items: center;
            }

            .service-history-accordion-title-details-left {
                width: unset;
            }

            .service-history-accordion-title-details-right {
                width: unset;
            }

            div.ford-col-logo {
                img {
                    height: 22px;
                    width: 60px;
                }

                &.hide {
                    display: none;
                }

                @include mobile {
                    padding: 0 20px 10px 0;
                    min-height: 22px;
                    max-width: 81px;
                    box-sizing: border-box;

                    &.hide {
                        visibility: unset;
                        display: none;
                    }
                }
            }

            .service-date {
                height: 26px;
                width: auto;
                color: $fds-color-primary;
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 26px;
                padding-right: 46px;
                padding-left: 21px;

                @include desktop {
                    min-width: 116px;
                }

                @include mobile {
                    width: auto;
                    padding: 0 0 10px 0;
                }
            }

            .service-item {
                height: 42px;
                color: $fds-color-dk-gray;
                font-family: $f1-font-regular;
                font-size: 12px;
                line-height: 21px;
                min-width: 200px;

                @include mobile {
                    padding: 0;
                    height: unset;
                    min-width: 300px;
                }

                @include mobile-landscape {
                    width: 100%;
                }

                p {
                    @include mobile {
                        max-width: 90%;
                    }
                }
            }

            .service-category {
                width: auto;
                color: $fds-color-dk-gray;
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 26px;
                padding-right: 37px;
                margin-left: auto;

                &.hide {
                    display: none;
                }

                @include tablet {
                    min-width: 122px;
                }

                @include mobile {
                    padding: 10px 0 0 0;
                    margin: 0;

                    p {
                        font-size: 16px;
                        line-height: 26px;
                        text-align: left;
                    }
                }
            }

            .fds-chevron-wrap {
                position: absolute;
                right: 25px;
                top: 30px;

                @include mobile {
                    padding: 0;
                    top: 25px;
                }

                div span:before {
                    font-size: 20px;
                }

                div span {
                    color: $fds-color-primary;
                }
            }
        }
    }
}

.service-history-accordion > div > div:nth-last-child(2).accordion-section {
    border-bottom: 2px solid $fds-color-lt-gray;
}

.service-history-title {
    h1,
    h2 {
        height: 40px;
        font-family: $f1-font-light;
        font-size: 34px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        color: $fds-color-dk-gray;
        @include mobile {
            height: 28px;
            font-size: 34px;
            line-height: 1.42;
            text-align: center;
            color: $fds-color-dk-gray;
            margin-left: 12.5px;
            margin-right: 12.5px;
            margin-bottom: 60px;
        }
    }
}

.button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile {
        margin-top: 20px;
    }
}

.service-history-accordion-details-header {
    font-family: $f1-font-regular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    color: $fds-color-dk-gray;
}

.service-history-accordion-details-item {
    font-family: $f1-font-regular;
    font-size: 1rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    color: $fds-color-primary;

    .ford-logo {
        background: transparent no-repeat -200px 0;
        width: 5.8125rem;
        height: 2.1875rem;
        background-size: 25rem 12.5rem;
        display: inline-block;
        padding: 0;
        margin: 0 0 0 0;

        &.hide {
            display: none;
        }
    }
}

.service-history-accordion-title-details-left {
    width: 35%;
    text-align: left;
    @include mobile {
        width: 50%;
        padding-left: 2.5%;
    }
}

.service-history-accordion-title-details-right {
    width: 65%;
    text-align: left;
    @include mobile {
        width: 50%;
    }
}

.service-history-modified-on {
    font-family: $f1-font-regular;
    font-size: 1rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    color: $fds-color-primary;
}

.service-history-panel-item {
    text-align: left;
    display: flex;

    @include mobile {
        flex-direction: column-reverse;
    }
}

.service-history-item-header {
    color: $fds-color-primary;
    font-family: $f1-font-regular;
    font-size: 16px;
    line-height: 26px;
    text-transform: uppercase;
    text-align: left;
}

.service-history-item {
    font-family: $f1-font-light;
    color: $dropdown-text;
    font-size: 1rem;
    text-align: left;
    padding-top: 1%;
    @include mobile {
        width: 100%;
        font-size: 1rem;
    }
}

.service-history-mileage-date {
    width: 100%;
    display: inline-flex;
    text-align: left;
}

.service-history-accordion-article {
    border-top: 1px solid $fds-color-lt-gray;
    background-color: #f4f4f4;
    padding: 0 0 20px 26px;

    .service-history-item-header {
        padding-top: 24px;
    }

    .service-history-item {
        padding-top: 0;
        color: $fds-color-dk-gray;
        font-family: $f1-font-regular;
        font-size: 16px;
        line-height: 26px;
    }

    .total-cost {
        color: $fds-color-dk-gray;
        font-family: $f1-font-light;
        font-size: 24px;
        line-height: 34px;
    }

    .inspections,
    .operations {
        @include desktop {
            max-width: 300px;
        }
    }

    li {
        list-style: none;
        padding-top: 20px;

        .check-img {
            margin-right: 16px;
            height: 16px;
            width: 16px;
        }
    }

    .service-history-accordion-title-details-left.ford-col-logo {
        @include mobile {
            padding: 0;
        }
    }

    .ford-col-logo {
        &.hide {
            display: none;
        }

        p {
            position: relative;
        }

        img {
            width: 40px;
            height: 15px;
            margin: 13px 4px 0 0;
        }

        span {
            position: absolute;
            top: 16px;
            left: 46px;
            color: $fds-color-dk-gray;
            font-family: $f1-font-regular;
            font-size: 10px;
            line-height: 12px;
        }
    }

    .owner-edit-msg {
        padding-top: 27px;
        text-align: left;
        font-size: 12px;
        line-height: 21px;
    }
}

.service-history-panel-button {
    margin: 5% 2% 0 0;
    display: flex;
    text-align: left;
    @include mobile {
        margin-bottom: 2.5%;
    }
}

.service-history-panel-button-edit {
    margin-right: 0.5%;
}

.service-history-panel-item-button {
    padding-top: 31px;
    @extend .service-history-panel-item;
    @include mobile {
        flex-direction: row;
    }
}

.service-history-add-record-button-symbol {
    margin-bottom: -3%;
    margin-left: 0.5rem;
}

.service-history-download-icon {
    content: url('../../../assets/download-icon.svg');
    width: pToR(24);
    height: pToR(24);
    margin-right: pToR(12);
}

.service-history-download-button-format {
    display: flex;
    align-items: center;
    @include mobile {
        margin-top: pToR(29);
    }
}

.service-history-download-button-label {
    color: $dark-blue;
}

.service-history-limited-view-icon {
    content: url('../../../assets/error-warning.svg');
    width: pToR(16);
    height: pToR(16);
    margin-right: pToR(12);
}

.service-history-limited-view-format {
    display: flex;
    align-items: center;
    margin-left: pToR(20);

    @include mobile {
        margin-top: pToR(13);
        margin-left: 0;
        justify-content: center;
    }
    .service-history-limited-view-label {
        color: $fds-color-dk-gray;
        font-family: $f1-font-light;
        text-transform: uppercase;
        text-decoration: underline;
        line-height: 20px;
        font-size: 14px;
    }
}

.popover-container::before {
    content: '';
    position: absolute;
    left: -10px !important;
    top: 75px;
    width: 11px;
    border-top: 10px solid transparent;
    border-right: 10px solid $white;
    border-bottom: 10px solid transparent;
    -webkit-box-sizing: border-box;
    @include mobile {
        display: none;
    }
}

.limited-view-popover {
    .popover-rte {
        p {
            position: relative;
            display: inline-block;
            margin-right: 600px;
            padding-top: 15px;
            padding-left: 5px;

            &::after {
                content: $fds-chevron-dot;
                font-family: FdsFordIcons, sans-serif;
                color: $fds-color-primary;
                height: 13px;
                width: 13px;
                position: absolute;
                right: 0;
                border-radius: 13px;
            }
        }

        a {
            color: $fds-color-primary;
            font-family: $f1-font-regular;
            font-size: 18px;
            line-height: 13px;
            display: inline-block;
            text-decoration: none;

            &::after {
                content: $fds-chevron-right;
                font-family: FdsFordIcons, sans-serif;
                color: $white;
                position: absolute;
                right: -5px;
                top: 21px;
                z-index: 3;
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
            }
        }
    }
}
.popover-next-service-due {
    .popover-container::before {
        top: 140px;
        left: 300px;
    }
    .next-service-due-tooltip-text {
        padding-top: 30px;
        p {
            padding-right: 0px !important;
            @include mobile {
                padding-right: 25px !important;
            }
        }
    }
}

.your-next-service-tooltip {
    margin-left: pToR(10);
    background: none;
    cursor: pointer;
    border: none;
    .your-next-service-tooltip-icon {
        width: pToR(24);
        height: pToR(24);
        margin-bottom: -5px;
    }

    @include mobile {
        align-items: end;
        padding: 0 1px 0 20px;
        margin-left: 0;
        justify-content: center;
    }
}

.service-history-panel-modal-confirmation-button {
    padding-top: 10%;
    justify-content: left;
    @extend .service-history-panel-item;
    @include mobile {
        flex-direction: row;
    }
}

.service-history-modal-header {
    padding-bottom: 2.5%;
}

.service-history-modal-styling {
    font-family: $f1-font-light;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    color: $fds-color-dk-gray;
}

.service-history-add-service-error-modal {
    width: pToR(1030) !important;
    height: pToR(447) !important;
    //left: calc(50% - 515px) !important;
    @include mobile {
        width: 100% !important;
        height: pToR(520) !important;
        left: 0px;
    }
}

.service-history-add-service-error-modal-header {
    padding-top: pToR(96);
    padding-bottom: pToR(16);
    font-family: $f1-font-light;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    text-align: center;
    color: $fds-color-dk-gray;
    @include mobile {
        padding-top: 4rem;
    }
}

.service-history-add-service-error-modal-styling {
    text-align: left;
    @extend .service-history-modal-styling;
}

.service-history-panel-add-service-error-modal-confirmation-button {
    padding-top: pToR(48);
    justify-content: left;
    @extend .service-history-panel-item;

    @include mobile {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .primary-button {
        padding: 0 1rem !important;
    }
}

.service-history-not-available {
    text-align: left;
    color: $text-gray;
    font-family: $f1-font-regular;
    font-size: 1rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    margin-top: 30px;
    margin-bottom: 200px;

    @include mobile {
        margin-right: 25px;
        margin-left: 25px;
        text-align: center;
    }

    h2 {
        font-family: $f1-font-light;
        font-size: 48px;
        font-stretch: normal;
        font-style: normal;
        line-height: 58px;
        margin-bottom: 25px;
        text-transform: uppercase;

        @include mobile {
            font-size: 34px;
            line-height: 44px;
        }
    }

    p {
        font-size: 16px;
        line-height: 26px;
        text-align: left;
    }
}

.service-failure {
    margin: 7%;
    margin-top: 30px;

    @include mobile {
        margin-top: 20px;
        margin-right: 23px;
    }

    p {
        @include mobile {
            text-align: left;
        }
    }
}

.transparent-no-chevron {
    border: 0;

    &:hover,
    &:active {
        border: 0;
    }
}

.error-na-region {
    padding-top: 0;
}

.delete-confirmation-footer {
    display: flex;
    justify-content: left;
    padding-top: pToR(15);

    button.no-shadow-transparent {
        margin: 0 pToR(17);
    }

    .primary-button:hover {
        transform: none;
    }
}
.service-history-vehicle-details {
    margin: 20px 7% 20px 7%;
    box-shadow: 0px 3px 3px 1px $fds-color-border-md-gray;
    padding: 20px 32px 20px 40px;
    max-height: 116px;

    @include tablet {
        margin-top: 30px;
    }

    @include desktop {
        margin-top: 20px;
    }

    @include mobile {
        max-height: fit-content;
        padding: 16px 15px 15px 15px;
    }

    h2 {
        font-family: $f1-font-regular;
        color: $fds-color-dk-gray;
        line-height: 34px;
    }

    .service-history-vehicle-details-wrapper {
        display: inline-block;
        width: 100%;
    }

    .service-history-vehicle-details-left {
        float: left;
    }

    .service-history-vehicle-details-info {
        .service-history-vehicle-start-date {
            margin-top: 5px;
            font-size: 16px;
            line-height: 26px;
            display: inline-block;
            @include mobile {
                margin-top: 20px;
            }
        }

        .service-history-vehicle-odometer {
            @include desktop {
                font-size: 16px;
                line-height: 26px;
                display: inline-block;
            }

            @include mobile {
                position: relative;
                margin-top: 20px;
            }

            .info-wrapper {
                position: relative;
                cursor: pointer;

                .odometer-info-icon {
                    content: url('../../../assets/info-icon.svg');
                    position: absolute;
                    margin: 0;
                    padding: 0;
                    font-weight: bold;
                    width: 24px;
                    height: 24px;
                    color: $dark-blue;
                    left: 10px;
                }
            }
        }

        .service-history-start-date-label {
            font-family: $f1-font-regular;
            color: $fds-color-dk-gray;

            @include mobile {
                display: block;
            }
        }

        .service-history-odometer-label {
            font-family: $f1-font-regular;
            color: $fds-color-dk-gray;

            @include mobile {
                display: block;
            }
        }

        .service-history-odometer {
            font-weight: bold;
            color: $fds-color-dk-gray;

            @include desktop {
                margin-left: 10px;
            }
        }

        .tooltip {
            top: 8px;

            @include mobile {
                position: absolute;
                top: 16px;
            }

            .tooltip__infoIcon__dark {
                font-size: x-large;
            }

            .tooltip__wrapper {
                display: none;
            }
        }

        .odometer-info-icon {
            position: fixed;
            padding-left: 10px;
        }
    }

    .vehicle-name-centered {
        @include tablet {
            padding-top: 20px;
            margin: 0;
        }
    }
}

.service-history-vehicle-title-right {
    display: flex;
    flex-direction: row;
    float: right;

    .primary-button .button-label {
        line-height: 13px;
    }

    @include tablet {
        padding: 18px 0;
    }

    @include mobile {
        width: 100%;
        margin: 26px 0 20px;
        flex-direction: column;
        align-items: center;
    }

    a.primary-button {
        padding: 0 25px;
    }
}
@media print {
    .support-section,
    .next-service-due-section,
    .hide-on-desktop,
    .service-history-vehicle-details {
        display: none !important;
    }
}
.support-section {
    .feature-trio {
        margin: 0 9.2% !important;
    }
    .feature-trio-title {
        @include mobile {
            width: 94%;
            margin-left: 3% !important;
        }
    }
    .card-header {
        @include mobile {
            height: 152px !important;
            width: 94% !important;
            margin-left: 3%;
        }
    }
    .card-description {
        @include mobile {
            width: 96% !important;
            margin-left: 4%;
            margin-top: 0 !important;
        }
    }
}
.sh-article-carousel {
    margin: 64px 16px 0 16px;
    @include desktop {
        margin: 96px 16px 0 56px;
    }
    .sh-article-carousel__title {
        font-size: 40px;
        line-height: 48px;
        color: $fm-text-grey;
        font-family: $f1-font-regular;
    }
    .sh-article-carousel__subtitle {
        margin: 16px 16px 0 0;
        font-size: 16px;
        line-height: 24px;
        color: $f1-text-blue;
        font-family: $f1-font-regular;
    }
    .fm-carousel {
        &__container {
            background-color: $fds-color-surface;
            margin-top: 0;
            padding: 0;
        }
        &__items-item {
            .fm-card {
                &__container {
                    background-color: $fds-color-surface;
                }
                &__title {
                    @include desktop {
                        font-size: 32px;
                        line-height: 40px;
                    }
                    font-family: $f1-font-regular;
                    color: $fm-text-grey;
                    font-size: 28px;
                    line-height: 36px;
                }
            }
        }
    }
}

.next-service-section {
    margin: 40px 7%;
}

.next-service-due-section {
    display: flex;
    justify-content: center;
    font-family: $f1-font-regular;
    font-size: 16px;
    line-height: 1.63;
    color: $fds-color-dk-gray;

    @include mobile {
        align-items: end;
        text-align: left;
    }
}

.hide-on-mobile {
    @include mobile {
        display: none;
    }
}

.hide-on-desktop {
    display: grid;
    place-content: center;

    .service-history-title-right {
        margin: 0;
        padding-bottom: 25px;
    }

    .service-history-download-button-format {
        margin: 0;
    }

    @include tablet {
        display: none;
    }
}
.service-up-to-date {
    display: flex;
    justify-content: center;
    font-family: $f1-font-regular;
    font-size: 16px;
    line-height: 1.63;
    color: $fds-color-dk-gray;
    padding-bottom: 12px;

    @include mobile {
        justify-content: space-between;
        text-align: left;
        padding-bottom: 20px;
        align-items: end;
    }

    .notification-icon {
        margin-left: 8px;
        height: min-content;

        @media (max-width: 375px) {
            position: relative;
            top: 4px;
        }

        @include mobile {
            margin: 0;
            padding: 0 0 0 34px;
        }
    }
}
@import './service-history-accordion/service-history-accordion.ford.scss';
@import './service-history.lincoln.scss';
