@import '../../../../styles/main.scss';

.brand-lincoln {
    .service-history-accordion {
        .secondary-button {
            .secondary-button-link {
                padding: 25px 20px;
            }
        }
        .accordion-title p {
            font-size: 17px;
            font-family: $proxima-nova-regular;
            line-height: 1.59;
            letter-spacing: unset;
            color: $brand-secondary;
        }
        .accordion-section {
            border: none;

            .fds-chevron {
                font-family: FdsFordIcons, sans-serif;
                top: 30px !important;
            }
            div.ford-col-logo {
                img {
                    content: url('../../../../assets/lincoln-logo.png');
                    width: 139px;
                    max-height: 36px;
                    background-size: 139px 36px;
                    margin-left: -15px;

                    @include mobile {
                        width: 100px;
                        margin-top: 5px;
                        margin-left: -19px;
                    }
                }
            }
            .header-logo {
                @include mobile {
                    margin-left: -10px !important;
                }
            }
        }
        .accordion-section .accordion-title {
            border: none;
            border-top: 1px solid $brand-gray3;
            .fds-chevron-wrap {
                padding: 0px !important;
                top: 30px !important;
                .up {
                    transform: none;
                    .fds-arrow {
                        &.unfilled {
                            &::before {
                                position: absolute;
                                margin-top: -5px;
                                margin-left: -12px;
                                padding-left: 0px !important;
                            }
                        }
                    }
                }
            }
            div.ford-col-logo {
                @include mobile {
                    max-width: 120px;
                }
                img {
                    position: relative;
                    top: 4px;
                }
            }
        }
        .accordion-section .accordion-title .service-item {
            max-width: 60%;
        }
        .accordion-section .accordion-title .service-date {
            @include mobile {
                padding: 0 0 10px 0;
                width: auto;
            }
        }
        .accordion-section button[aria-expanded='true'] {
            box-shadow: none;
        }

        .service-history-panel-item-inner .service-history-item-header,
        .service-history-panel-item-inner .service-history-item,
        .owner-edit-msg {
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
        }

        .service-history-accordion-article .service-history-item-header {
            font-size: 17px;
        }
        .service-history-accordion-article .service-history-item {
            font-size: 18px;
        }
        .service-history-accordion-article {
            .ford-col-logo {
                span {
                    top: 25px !important;
                    left: 120px;
                    @include mobile {
                        top: 17px !important;
                    }
                }
            }
        }
        .service-history-accordion-title-details-left {
            @include mobile {
                width: 50%;
                padding-left: 2.5%;
            }
        }
    }
}
