.brand-lincoln {
    $tile-size: 290px;

    .smart-tile-grid-wrapper {
        max-width: $max-content-width;
        text-align: center;
        color: $text-gray;
        padding: 16px 16px 0 16px;
        margin: 60px auto;
        @include desktop {
            margin: 100px auto;
            padding: 0;
        }
        &.carousel-wrap {
            overflow: hidden;
            padding-bottom: 20px;

            .inner-smart-tile-grid-carousel {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: left;

                @media (min-width: 1350px) {
                    margin-left: 3%;
                }

                > div {
                    @include mobile {
                        flex-shrink: 0;
                        width: 90%;
                        margin-right: 15px;
                    }
                }
            }
            .carousel-buttons {
                padding-top: 30px;
                padding-bottom: 30px;
                display: flex;
                justify-content: center;
                align-items: center;

                .indicator-text {
                    margin: 0 25px;
                }

                .smart-tile-carousel-button {
                    width: 42px;
                    height: 42px;
                    justify-content: center;

                    &::before {
                        border-radius: 3px;
                    }

                    .fds-arrow {
                        font-size: 22px;
                    }
                }
            }
        }

        h2 {
            font-family: $proxima-nova-regular;
            text-transform: uppercase;
            display: inline-block;
            font-size: 1.5rem;
            letter-spacing: 2px;
            margin: 0 0 12px 0;
            padding: 0;
        }

        .paragraph {
            font-family: $proxima-nova-regular;
            font-size: 1.1rem;
            line-height: 1.625rem;
        }

        .inner-smart-tile-grid {
            display: flex;
            flex-direction: column;
            margin: 60px auto;

            @include tablet {
                max-width: $max-content-width;
                flex-flow: row wrap;
                justify-content: center;
                padding: 0;
            }
        }
        .smart-tiles-cta {
            display: inline-flex;
            margin-bottom: 19px;
        }
    }
}
