@import '../../../../styles/main.scss';
.vehicle-vin-input {
    display: flex;
}
.spc-desktop-search-where-to-find-vin-link {
    margin: 5px 0;
    .spc-desktop-search-input-link-text {
        color: #00095b;
        font-family: $f1-font-regular;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 24px;
        cursor: pointer;
    }
    .spc-desktop-search-input-link-icon {
        font-size: 22px;
        margin-left: 2%;
    }
}
.spc-vehicle-link {
    cursor: pointer;
    text-decoration: underline;
    color: #00095b;
    font-family: $f1-font-regular;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    @include mobile {
        margin: 5px 0;
    }
}
