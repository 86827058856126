@import '../../../styles/main.scss';
.quick-link-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    @include mobile {
        flex: 0;
        width: unset;
    }

    .quick-link-category {
        color: $darkgrey;
        font-family: $f1-font-regular;
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
    }
    .quick-link {
        color: $fds-color-black;
        font-family: $f1-font-regular;
        font-size: 16px;
        font-style: normal;
        line-height: 24px;

        &:hover {
            color: $fm-breadcrumb-blue;
            text-decoration: underline;
        }
    }
}
