@import '../../../../styles/main.scss';
.dealer-tile-label {
    background-color: #1700f4;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    margin-bottom: -5px;
    color: #ffffff;
    font-family: $f1-font-regular;
    font-size: 11px;
    letter-spacing: 1px;
    line-height: 17px;
    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}
.dealer-tile-details {
    position: relative;
    z-index: 1;
    width: 444px;
    padding-bottom: 20px;
    .dealer-tile-name {
        text-transform: uppercase;
        background-color: white;
        color: #00095b;
        font-family: $f1-font-regular;
        font-size: 16px;
        letter-spacing: 2px;
        line-height: 26px;
        padding: 20px;
    }

    .dealer-tile-address-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        padding-bottom: 10px;
        .address-container {
            .dealer-tile-address-text {
                padding-left: 20px;
                color: #4d4d4d;
                font-family: $f1-font-regular;
                font-size: 12px;
                letter-spacing: 1.09px;
                line-height: 21px;
            }
            .dealer-tile-distance {
                padding: 20px;
                color: #4d4d4d;
                font-family: $f1-font-regular;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 1.09px;
                line-height: 21px;
            }
        }
        .dealer-tile-button-container {
            margin-right: 15px;
        }
    }
    .dealer-tile-activity-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f2f2f2;
        margin: 0;
        height: 200px;
    }
    .dealer-tile-services {
        background-color: #f2f2f2;
        .dealer-tile-service {
            display: flex;
            align-items: center;
            margin: 0;
            height: 89px;
            color: #00095b;
            font-family: $f1-font-regular;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 18px;
            padding: 10px 20px;
            .dealer-tile-service-info {
                display: block;
                align-items: center;
                flex: 1;
                .dealer-tile-service-info-icon {
                    font-size: 24px;
                    margin-left: 2%;
                }
                .tooltip__infoIcon {
                    &.dark {
                        background-color: $fds-color-surface;
                    }
                }
            }
            .dealer-tile-service-price {
                font-size: 18px;
                font-weight: bold;
                margin-left: 20px;
            }
        }
        .service-separator {
            height: 1px;
            background-color: #bbbbbb;
            width: 100%;
        }
    }
    .dealer-tile-convenience-option {
        .fmc-accordion {
            .fmc-accordion__panel {
                border-top: none;
                border-bottom: none;
                .fmc-accordion__body {
                    max-height: 417px;
                    overflow-y: scroll;
                    background-color: $fds-color-white;
                    &::-webkit-scrollbar {
                        width: 5px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: $fds-color-border-gray;
                    }

                    .fmc-accordion__content {
                        padding: 5px;
                        .fmc-type--body1 {
                            text-transform: uppercase;
                            color: #00095b;
                            font-family: $f1-font-regular;
                            font-size: 12px;
                            font-weight: bold;
                            letter-spacing: 2px;
                            line-height: 18px;
                            .dealer-tile-service {
                                color: #00095b;
                                font-family: $f1-font-regular;
                                font-size: 12px;
                                font-weight: bold;
                                letter-spacing: 2px;
                                line-height: 18px;
                                display: flex;
                                padding: 10px 20px;
                                .dealer-tile-service-info {
                                    display: block;
                                    justify-content: space-between;
                                    align-items: center;
                                    .dealer-tile-service-name {
                                        max-width: 200px;
                                        word-wrap: break-word;
                                    }
                                    .dealer-tile-service-info-icon {
                                        font-size: 24px;
                                    }
                                    .tooltip__infoIcon {
                                        &.dark {
                                            background-color: $fds-color-white;
                                        }
                                    }
                                }
                                .dealer-tile-service-price {
                                    margin-left: auto;
                                }
                            }
                            .dealer-tile-line {
                                border-bottom: 1px solid black;
                                margin-left: 20px;
                            }
                        }
                    }
                }

                .fmc-accordion__button {
                    border-bottom: none;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    position: relative;
                    .fmc-accordion__button-title {
                        text-transform: uppercase;
                        margin: 0;
                        padding: 0;
                        color: #ffffff;
                        font-family: $f1-font-regular;
                        font-size: 12px;
                        letter-spacing: 1px;
                        line-height: 18px;
                    }
                    .fmc-accordion__button-expand {
                        position: absolute;
                        top: 50%;
                        right: 15px;
                        transform: translateY(-50%);
                    }
                }
            }
            .fmc-accordion--expanded {
                .fmc-accordion__button {
                    border-bottom: 0.5px solid $fds-color-dk-gray;
                }
            }
        }
    }
}
