@import '../../../../styles/main.scss';

.dealer-service-card-container {
    display: grid;
    grid-template-columns: 45% 25% 30%;
    min-height: 102px;
    width: 150%;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 25px;
    align-items: center;
    padding: 20px 15px 20px 15px;
    height: auto;

    @include mobile {
        grid-template-columns: none;
        min-height: 204px;
        width: 120%;
        margin-left: -14%;
        margin-top: 5%;
    }

    .service-name-wrapper {
        .service-name-with-offer {
            @include mobile {
                margin: 0 auto;
                width: 80%;
            }

            .pc-offer-label {
                background-color: #008200;
                font-family: $f1-font-regular;
                font-size: 12px;
                letter-spacing: 1px;
                color: #ffffff;
                width: -moz-fit-content;
                width: fit-content;
                margin-bottom: 5px;
                padding: 2px 5px;
            }
            .service-name-prefix {
                color: #102b4e;
                font-family: $f1-font-medium;
                font-size: 16px;
                letter-spacing: 2px;
                line-height: 26px;
                text-transform: uppercase;
                width: 100%;
            }
        }
        .service-type-wrapper {
            display: flex;
            margin-top: 10px;

            @include mobile {
                width: 100%;
                justify-content: center;
                margin-top: 3px;
            }

            .service-name-suffix {
                color: #4d4d4d;
                font-family: $f1-font-regular;
                font-size: 12px;
                letter-spacing: 1.09px;
                line-height: 21px;
                padding-top: 5px;
            }

            .tooltip__infoIcon {
                &.dark {
                    font-size: 20px;
                }
            }
        }
    }

    .dealer-service-price {
        color: #102b4e;
        font-family: $f1-font-medium;
        font-size: 20px;
        line-height: 34px;
        margin-left: 5%;

        @include mobile {
            margin-left: 0;
            margin-top: 17px;
            display: flex;
            justify-content: center;
        }
    }

    .dealer-service-por-price {
        color: #102b4e;
        font-family: $f1-font-medium;
        font-size: 16px;
        margin-left: 7%;

        @include mobile {
            margin-left: 0;
            margin-top: 17px;
            text-align: center;
        }
    }

    .book-now-button {
        display: flex;
        justify-content: center;

        .primary-button {
            max-width: 175px;
            justify-content: center;
            padding: 0 22px;
            .button-label.no-chevron {
                white-space: break-spaces;
                margin-top: 5%;
                margin-bottom: 5%;
            }
        }
        .button-label {
            font-size: 18px;
            font-family: $f1-font-regular;
            line-height: 1.25rem;
            text-align: center;
            color: #ffffff;
        }

        @include mobile {
            margin-top: 18px;
            margin-left: 0;
        }
    }
}
