@import '../../../../styles/main.scss';

.notification-message {
    position: sticky;
    height: 56px;
    box-sizing: border-box;
    background-color: $fds-color-white;
    margin-top: 0;
    padding: 18px 0 12px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    z-index: 2000;
    align-items: center;
    box-shadow: 0 12px 16px 4px rgba(0, 0, 0, 0.24);

    img {
        margin-left: 28px;
        height: auto;
        width: 16px;
        align-self: baseline;

        @include mobile {
            align-self: flex-start;
            margin-left: 15px;
            width: 22px;
            height: auto;
            margin-top: 5px;
        }
    }

    @include mobile {
        height: auto;
    }

    @include desktop;
    @include tablet {
        margin-bottom: 84px;
    }
}
.error {
    color: $fds-color-red;
    img {
        content: url('../../../../assets/notification-error.svg');
    }
}
.success {
    border-bottom: 8px solid $fds-color-green;

    img {
        content: url('../../../../assets/success-checkmark.svg');
        margin-left: 7%;
        height: 22px;
        width: 22px;

        @include mobile {
            margin-left: 16px;
        }
    }
}
.notification-text {
    margin-left: 20px;
    padding-left: 0;
    color: $fds-color-dk-gray;
    font-family: $f1-font-regular;
    font-size: 16px;
    line-height: 26px;
    padding-top: 2px;

    @include mobile {
        margin-left: 10px;
        margin-right: 35px;
        padding-top: 0;
        font-size: 14px;
        line-height: 20px;
    }

    h1 {
        display: none;
    }
}

.deleteRecord {
    margin-bottom: 44px;
    padding-top: 16px;

    @include mobile {
        margin-bottom: 21px;
    }

    .notification-text {
        @include mobile {
            line-height: 20px;
            font-size: 14px;
        }
    }
}

.brand-lincoln {
    .notification-message {
        justify-content: center;
        min-height: 40px;
        box-shadow: none;
        padding: 0 7%;
        height: auto;

        @include tablet {
            box-sizing: border-box;
            padding: 5px 7%;
        }

        img {
            margin-left: 0;
            align-self: center;
            width: 20px;
        }

        &.error {
            background-color: $brand-attention;
            border-bottom: none;
            img {
                content: url('../../../../assets/lincoln-icons/error-notification.svg');
            }
        }
        &.success {
            background-color: $brand-success-alert;
            border-bottom: none;
            img {
                content: url('../../../../assets/lincoln-icons/checkmark.svg');
            }
        }
        .notification-text {
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
            margin-left: 10px;
        }
    }
}
