@import '../../../../styles/main.scss';

.list-actions {
    position: relative;
    top: 1%;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 40px 0 0px 0;
    background-color: #fff;
    &.fixed {
        position: fixed;
        top: 210px;
    }
    .filter-link,
    .list-link {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $fds-color-white;
        color: $fds-color-primary;
        border-radius: 20px;
        padding: 10px;
        font-size: 16px;
        cursor: pointer;
        margin-right: 14px;
        margin-bottom: 30px;
        width: 35%;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2),
            0px 0px 6px rgba(0, 0, 0, 0.2);
        .filters-text,
        .list-text {
            margin-left: 10px;
            color: $fds-color-primary;
            font-family: $f1-font-regular;
            font-size: 18px;
            letter-spacing: 1px;
        }
    }
}
.dealer-detail-container {
    padding: 20px;
    background-color: #fff;
    .preferred-dealer-label {
        position: absolute;
        // margin-bottom: 5px;
        left: 56px;
        background-color: $fds-color-secondary;
        font-family: $f1-font-regular;
        color: $fds-color-white;
        font-size: 11px;
        letter-spacing: 1px;
        line-height: 17px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 5px 10px;
    }
    .dealer-details-section {
        display: grid;
        grid-template-columns: 9% 90%;
        grid-column-gap: 1%;
        // margin: 20px 0;
        padding: 25px 0 0 0;
        .dealer-sequence-number {
            color: #00095b;
            font-family: $f1-font-regular;
            font-size: 32px;
            letter-spacing: 2px;
            line-height: 26px;
            text-align: center;
            margin-top: 5px;
        }
        .dealer-info {
            .dealer-name {
                color: #00095b;
                font-family: $f1-font-regular;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 2px;
                line-height: 26px;
                text-transform: uppercase;
            }
            .dealer-address-container {
                .dealer-tile-address-text {
                    color: #4d4d4d;
                    font-family: $f1-font-regular;
                    font-size: 12px;
                    letter-spacing: 1.09px;
                    line-height: 21px;
                    margin: 5px 0;
                }
                .dealer-distance {
                    color: #4d4d4d;
                    font-family: $f1-font-regular;
                    font-size: 12px;
                    letter-spacing: 1.09px;
                    line-height: 21px;
                }
            }
            .dealer-cta-container {
                margin: 30px 0;
            }
        }
    }
    .dealer-separator {
        border-bottom: solid 0.5px #636363;
        margin: 5px 0;
        box-shadow: none;
    }

    .spc-desktop-map-overlay-view-more {
        display: flex;
        justify-content: center;
        margin: 10px 0;
        .spc-desktop-map-view-more-text {
            color: $fds-color-primary;
            font-family: $f1-font-regular;
            font-size: 18px;
            letter-spacing: 1px;
            line-height: 24px;
            margin-right: 10px;
        }
        .fds-chevron.down {
            color: $fds-color-primary;
        }
    }
}
