@import '../../../../styles/main.scss';
.spc-mobile-search-wrapper {
    padding: 20px;
    .spc-mobile-search-heading-container {
        font-family: $f1-font-regular;
        .spc-header {
            color: #00095b;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 30px;
            margin: 20px 0;
        }
        .spc-sub-header {
            color: #4d4d4d;
            font-size: 16px;
            letter-spacing: 0.75px;
            line-height: 24px;
            margin: 10px 0;
            .sign-in {
                display: inline-block;
                margin-left: 5px;
                color: #102b4e;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
    .spc-mobile-search-input-container {
        margin: 25px 0;
    }
    .spc-mobile-search-input-vin-container,
    .spc-mobile-search-input-mileage-container,
    .spc-mobile-search-input-location-container {
        margin: 20px 0;
    }
    .spc-mobile-search-input-vin-container {
        display: flex;
        .spc-mobile-search-input-vin {
            width: 100%;
            .spc-input-container {
                width: 95%;
            }
        }
        .vin-reg-info-icon {
            cursor: pointer;
            color: #102b4e;
            font-family: $f1-font-regular;
            .find-vin-icon {
                font-size: 30px;
                margin-left: 2%;
            }
            .find-vin-text {
                font-size: 16px;
                line-height: 26px;
                letter-spacing: 1px;
                text-decoration: underline;
            }
        }
    }
    .spc-mobile-search-input-location-container {
        .auto-complete-label-container {
            display: none;
        }
        .autoCompleteText {
            z-index: 10;
            button {
                width: 35px;
                .fds-chevron-wrap {
                    display: block;
                }
            }
        }
        .spc-mobile-search-input-autocomplete.disabled {
            pointer-events: none;
            opacity: 0.5;
        }

        .spc-enable-location-link {
            color: #00095b;
            font-family: $f1-font-regular;
            font-size: 16px;
            text-decoration: underline;
            cursor: pointer;
            padding-bottom: 10px;
            margin: 10px 0;
        }
        .spc-preferred-dealer-message {
            flex: 0 0 auto;
            font-family: $f1-font-regular;
            font-size: 9px;
            background-color: #102b4e;
            border: 2px solid #102b4e;
            padding: 5px;
            margin-bottom: 10px;
            border-radius: 5px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: left;
        }
        .pc-input-label {
            margin: 5px;
            height: auto;
        }
        .spc-input-error {
            color: red;
            font-size: 12px;
            margin-top: 5px;
        }
    }
    .spc-mobile-search-input-use-my-location {
        .osb-use-location button {
            background-color: #f4f4f4;
        }
    }

    .find-my-vin-popup-container {
        .vin-reg-modal-popup {
            @include mobile {
                padding: 0;
                top: 0;
                bottom: 0;
                margin: 0;
            }
        }
    }
}
