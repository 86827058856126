.brand-lincoln {
    .smash-vehicle-selector-fds {
        max-width: $max-content-width;
        padding: 45px 16px;
        margin: 0 auto;
        .smash-dashboard-title {
            a {
                text-decoration: underline;
                color: $fds-color-dk-gray;
                padding: 9px 0;
                &:focus {
                    outline: 1px solid $brand-secondary;
                }
            }
        }
        .title-anchor {
            color: $fds-color-dk-gray;
            font-family: $proxima-nova-regular;
        }
        .smash-vehicle-selector-title {
            color: $primary-graphite;
            font-family: $proxima-nova-regular;
            font-size: 18px;
            margin-bottom: 24px;
            line-height: 26px;
            display: inline-block;
            letter-spacing: 0;
            text-align: center;
            @include desktop {
                width: 100%;
                line-height: 17px;
                padding-bottom: 0;
            }
            .second-signin-button {
                display: inline;
                border-bottom: 1px solid $primary-graphite;
                button {
                    margin: 0;
                    padding: 0;
                    font-family: $proxima-nova-regular;
                    font-size: 16px;
                    text-decoration: none;
                }
            }
        }

        .smash-vehicle-selector-button-ymm {
            @include mobile {
                margin-top: 55px;
            }
        }
        .smash-vehicle-selector-button-vin {
            @include mobile {
                margin: 36px auto 56px auto;
            }
            @include tablet {
                margin-top: 20px;
                height: 3.75rem;
                position: relative;
                top: 6px;
            }
        }
    }
    .fds-segmented-control {
        overflow: unset;
    }
    // Active
    .fds-segmented-control__button--active {
        & .fds-segmented-control__button::before {
            transform: scale(1);
        }
    }

    // Focus
    button.fds-segmented-control__button--focused {
        outline-width: (1px);
        outline-style: solid;
        outline-offset: (20px);
    }

    .fds-segmented-control__button-list-flex-container {
        display: flex;
        justify-content: center;
        grid-column: full;
    }

    .fds-segmented-control__button-list-wrapper {
        position: relative;
    }

    .fds-segmented-control__button-list {
        position: relative;
        display: inline-flex;
        border: 1.5px solid transparent;
        padding: 14px 18px;
        border-radius: 1px;
    }

    .fds-segmented-control__button {
        display: inline-flex;
        padding: 0 25px;
        text-decoration: none;
        background: none;
        border: none;
        border-right: 1px solid $fds-color-primary;
        cursor: pointer;

        &:last-child {
            border-right: none;
        }
    }

    .fds-segmented-control__button-text {
        display: block;
        white-space: nowrap;
        color: $primary-graphite;
        font-family: $proxima-nova-semi-bold;
        font-size: pToR(16);
        letter-spacing: 0;
        line-height: pToR(13);
    }

    .fds-segmented-control__slider-text {
        font-size: pToR(16);
        color: $fds-color-white;
        font-family: $proxima-nova-semi-bold;
        letter-spacing: 0;
        line-height: pToR(13);
    }

    .fds-segmented-control__slider {
        display: block;
        margin: 0;
        line-height: 24px;
        padding: 10px 43px;
        border-radius: 1px;
        border: 1px solid $primary-graphite;
        position: absolute;
        top: -1px;
        white-space: nowrap;
        text-align: center;
    }

    .fds-segmented-control__panel {
        display: none;

        &.fds-segmented-control__panel--show {
            display: flex;
        }
        .fds-segmented-control__panel-content {
            @include mobile {
                width: 100%;
            }
            margin: auto;
            .ymm-vin-container {
                max-width: unset;
                .input-field-container {
                    min-width: 320px;
                    input.input-field,
                    input.input-field-error {
                        max-width: 100%;
                        position: relative;
                        top: 20px;
                        left: -10px;
                    }
                    label.input-label {
                        top: -35px;
                        left: -10px;
                        font-size: 16px;
                        display: inline;
                    }
                    &:focus-within {
                        border-color: $brand-border;
                        border-width: 2px;
                        label.input-label {
                            top: -30px;
                            left: -10px;
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }

    .fds-color__bg--light {
        & .fds-segmented-control__button--focused {
            outline-color: black;
        }

        & .fds-segmented-control__button-list {
            border-color: $brand-gray3;
        }

        & .fds-segmented-control__slider {
            border-color: $primary-graphite;
            background-color: $primary-graphite;
            color: $fds-color-white;
            & .fds-segmented-control__button-text::after {
                color: $fds-color-white;
            }
        }
    }
}
