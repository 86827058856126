@import './fonts';
@import './colors';
@import './breakpoints';

body {
    @include print {
        color: $black;
    }
}

.shared-view {
    @include print {
        background-color: $white;
    }
}
.print-only {
    display: none;
    @include print {
        display: inline-block;
    }
}
.euheader,
.header,
.footer,
.brand-ford .footer,
.breadcrumbs {
    @include print {
        display: none;
    }
}
