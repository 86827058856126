@import '../../../../styles/main.scss';
.spc-desktop-map {
    width: 100%;
    height: 617px;
    position: relative;
    .osb-map-container {
        height: 100%;
    }
    .spc-desktop-map-overlay {
        position: absolute;
        top: 0;
        right: 22px;
        width: 100%;
        height: 100%;
        background-color: transparent;
        max-width: 455px;
        overflow-y: scroll;
        .spc-desktop-map-filter-component {
            width: 90%;
            height: 90%;
        }
        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 20px;
            border: 1px solid #ddd;
        }
        .spc-desktop-map-overlay-row {
            width: 100%;
            padding-top: 10px;
            display: flex;
            //justify-content: center;
            align-items: center;
            color: rgb(126, 15, 15);
            font-size: 18px;
        }
        .spc-desktop-map-overlay-filters {
            flex: 1;
            .spc-desktop-map-filter-button {
                display: flex;
                align-items: center;
                background-color: $fds-color-white;
                color: $fds-color-primary;
                border-radius: 20px;
                padding: 10px 25px;
                font-size: 16px;
                font-weight: bold;
                cursor: pointer;
                margin-right: 20px;
                margin-bottom: 30px;
                min-height: 40px;
                box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2),
                    0px 0px 6px rgba(0, 0, 0, 0.2);
                .spc-desktop-map-filters-text {
                    color: $fds-color-primary;
                    font-family: $f1-font-regular;
                    font-size: 18px;
                    letter-spacing: 1px;
                    line-height: 24px;
                    margin-left: 10px;
                }
            }
        }
        .spc-desktop-map-overlay-dealer-info {
            flex: 2;
        }
        .spc-desktop-map-overlay-view-more {
            flex: 1;
            .spc-desktop-map-view-more-button {
                display: flex;
                align-items: center;
                background-color: $fds-color-white;
                color: $fds-color-primary;
                border-radius: 20px;
                padding: 10px 25px;
                font-size: 16px;
                font-weight: bold;
                cursor: pointer;
                margin-right: 20px;
                margin-bottom: 30px;
                min-height: 40px;
                box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2),
                    0px 0px 6px rgba(0, 0, 0, 0.2);
                .spc-desktop-map-view-more-text {
                    color: $fds-color-primary;
                    font-family: $f1-font-regular;
                    font-size: 18px;
                    letter-spacing: 1px;
                    line-height: 24px;
                    margin-right: 10px;
                }
            }
        }
    }
}
