@import '../../../../styles/main.scss';

.vin-reg-modal-popup {
    width: 100%;
    object-fit: contain;
    padding: 50px 79px 50px 78px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #4d4d4d;
    @include mobile {
        padding: 0px;
        top: 0;
        bottom: 0;
        margin: 0;
    }
    .modal-content {
        width: 50%;
        @media (max-width: 1366px) {
            width: 75%;
        }
        border-radius: 3px;
        @include mobile {
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            margin: 0;
            border: none;
        }
        .modal-header {
            margin-bottom: 3rem;
        }
        .modal-close {
            width: 24px;
            height: 24px;
            margin-right: -4px;
            margin-top: 5px;
            @include mobile {
                width: 28px;
                height: 28px;
                margin-right: 8px;
                margin-top: 22px;
            }
            img {
                @include tablet-portrait {
                    width: 16px;
                    height: 16px;
                }
            }
        }
        .modal-footer {
            @include mobile {
                display: none;
            }
        }
    }
    .vin-info-title {
        width: 100%;
        margin: 0 1.1px 16px 1.9px;
        font-family: $f1-font-light;
        font-size: 20px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        color: #4d4d4d;
    }
    .vin-info-body-content {
        div {
            margin: 5px 1px 5px;
            font-family: $f1-font-regular;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            color: #4d4d4d;
            @include mobile {
                margin: 10px 1px 5px;
            }
        }
        .vin-info-list {
            list-style: none;
            display: flex;
            padding-top: 10px;
            @include mobile {
                display: block;
            }

            li {
                margin: 5px 10px;
                width: 45%;
                text-align: center;
                @include mobile {
                    width: 100%;
                    padding-bottom: 20px;
                }

                img {
                    width: 50px;
                    height: 50px;
                }

                p {
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.71;
                    @include mobile {
                        line-height: 1.5;
                        font-size: 16px;
                    }
                    text-align: center;
                    color: #4d4d4d;
                }
            }
        }
    }
    .modal-body {
        @include mobile {
            background-color: #ffffff;
            padding: 2rem 25px;
        }
        .vin-content,
        .reg-container {
            padding: 0 48px;
            @include mobile {
                padding: 0;
            }
        }
    }
    .modal-line {
        width: 90%;
        margin: 20px auto;
        border: solid 0.5px #d3d3d3;
    }
    .reg-container {
        .reg-content {
            text-align: center;
            width: 44%;
            padding-left: 30px;
            @include mobile {
                width: 100%;
            }
            img {
                width: 50px;
                height: 50px;
            }
            p {
                font-family: $f1-font-regular;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                @include mobile {
                    line-height: 1.5;
                    margin: 10px 1px 5px;
                }
                text-align: center;
                color: #4d4d4d;
            }
        }
    }
}
