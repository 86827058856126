@import '../../../../styles/main.scss';
.spc-input-container {
    position: relative;
    width: 270px;
    @include mobile {
        width: 100%;
    }
    .spc-input-placeholder {
        position: absolute;
        top: -15%;
        left: 0px;
        transform: translateY(-50%);
        font-family: $f1-font-regular;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 1px;
        color: #4d4d4d;
    }

    .spc-input-text {
        width: 270px;
        height: 52px;
        padding: 5px;
        @include mobile {
            width: 100%;
        }
        &::-webkit-input-placeholder {
            color: #6e6e6e;
            font-family: $f1-font-regular;
            font-size: 16px;
            letter-spacing: 0.78px;
            line-height: 19.2px;
        }
    }
    .spc-input-icon {
        position: absolute;
        top: -15%;
        transform: translateY(-50%);
        right: 10px;
        width: 20px;
        height: 20px;
    }
    .spc-input-error {
        color: red;
        font-size: 12px;
        margin-top: 5px;
    }
}
