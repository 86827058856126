.brand-ford {
    .service-history-accordion {
        .accordion-section {
            div.ford-col-logo {
                img {
                    content: url('../../../../assets/ford-logo.svg');
                }
            }
        }
    }
}
.service-history-popover .brand-ford .popover .popover-container {
    max-width: none;
}
