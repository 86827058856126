.brand-ford {
    .smash-vehicle-selector-fds {
        max-width: $max-content-width;
        padding: 45px 16px;
        margin: 0 auto;
        .smash-dashboard-title {
            a {
                text-decoration: underline;
                color: $fds-color-dk-gray;
                padding: 9px 0;
                &:focus {
                    outline: 1px solid $dark-blue;
                }
            }
        }
        .title-anchor {
            color: $fds-color-dk-gray;
            font-family: $f1-font-regular;
        }
        .smash-vehicle-selector-title {
            color: $fds-color-dk-gray;
            font-family: $f1-font-regular;
            font-size: 16px;
            margin-bottom: 24px;
            line-height: 26px;
            display: inline-block;
            @include desktop {
                width: 100%;
                line-height: 17px;
                padding-bottom: 0;
            }
            .second-signin-button {
                display: inline;
                button {
                    margin: 0;
                    padding: 0;
                    color: $fds-color-dk-gray;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    text-decoration: underline;
                }
            }
        }

        .vehicle-selector-title {
            color: $dark-blue;
            font-family: $f1-font-light;
            line-height: 44px;
            text-align: center;
            text-transform: uppercase;
        }

        .vehicle-selector-description {
            color: $fds-color-dk-gray;
            font-family: $f1-font-regular;
            margin-bottom: 24px;
            text-align: center;
        }

        .smash-vehicle-selector-button-ymm {
            margin-top: 55px;
            margin-bottom: 50px;
            @include tablet {
                margin-left: 25px;
            }
        }
        .smash-vehicle-selector-button-vin {
            @include mobile {
                margin: 0 auto;
            }
        }
    }
    .fds-segmented-control {
        overflow: unset;
    }
    // Active
    .fds-segmented-control__button--active {
        & .fds-segmented-control__button::before {
            transform: scale(1);
        }
    }

    // Focus
    button.fds-segmented-control__button--focused {
        outline-width: (1px);
        outline-style: solid;
        outline-offset: (20px);
    }

    .fds-segmented-control__button-list-flex-container {
        display: flex;
        justify-content: center;
        padding: 18px 0 18px;
        grid-column: full;
    }

    .fds-segmented-control__button-list-wrapper {
        position: relative;
    }

    .fds-segmented-control__button-list {
        position: relative;
        display: inline-flex;
        border-radius: (
            999px
        ); // for pill shape regardless of dimensions: https://www.w3.org/TR/css-backgrounds-3/#corner-overlap
        border: 1.5px solid transparent;
        padding: 14px 18px;
    }

    .fds-segmented-control__button {
        display: inline-flex;
        padding: 0 25px;
        text-decoration: none;
        background: none;
        border: none;
        border-right: 1px solid $fds-color-primary;
        cursor: pointer;
        &:last-child {
            border-right: none;
        }
    }

    .fds-segmented-control__button-text {
        display: block;
        white-space: nowrap;
        color: $fds-color-primary;
        font-family: $f1-font-regular;
        font-size: 18px;
        line-height: 13px;
    }

    .fds-segmented-control__slider-text {
        font-size: 18px;
        color: $fds-color-white;
        font-family: $f1-font-regular;
        line-height: 13px;
    }

    .fds-segmented-control__slider {
        display: block;
        margin: 0;
        line-height: 24px;
        padding: 10px 43px;
        border: 1px solid transparent;
        border-radius: 999px; // for pill shape regardless of dimensions: https://www.w3.org/TR/css-backgrounds-3/#corner-overlap
        position: absolute;
        top: -1px;
        white-space: nowrap;
        transition: 0.3s ease-in-out;
        text-align: center;
        box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1),
            0 10px 10px 0 rgba(0, 0, 0, 0.1);
    }

    .fds-segmented-control__panel {
        display: none;

        &.fds-segmented-control__panel--show {
            display: flex;
        }
        .fds-segmented-control__panel-content {
            @include mobile {
                width: 100%;
            }
            margin: auto;
            .ymm-vin-container {
                max-width: unset;
            }
        }
    }

    .fds-color__bg--light {
        & .fds-segmented-control__button--focused {
            outline-color: black;
        }

        & .fds-segmented-control__button-list {
            border-color: $fds-color-dk-gray;
        }

        & .fds-segmented-control__slider {
            border-color: $fds-color-primary;
            background-color: $fds-color-primary;
            color: $fds-color-white;

            & .fds-segmented-control__button-text::after {
                color: $fds-color-white;
            }
        }
    }
}
