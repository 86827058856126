@import '../../../../styles/main.scss';
.spc-desktop-search-wrapper {
    padding: 69px 60px 75px 142px;
    .spc-desktop-search-heading-container {
        height: 24px;
        width: 1079px;
        color: #4d4d4d;
        font-family: $f1-font-regular;
        font-size: 16px;
        letter-spacing: 0.75px;
        line-height: 24px;
        margin-bottom: 31px;
    }
    .spc-desktop-search-input-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto auto;
        gap: 10px;

        .spc-desktop-search-input-vin-container {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
            padding-bottom: 25px;
            .spc-desktop-search-input-vin {
                padding-bottom: 25px;
                .dropdown-container-wrapper {
                    padding-top: 0;
                }
            }
        }

        .spc-desktop-search-input-mileage-container {
            grid-column: 2 / 3;
            grid-row: 1 / 2;
            padding-left: 16px;
        }

        .spc-desktop-search-input-location-container {
            grid-column: 3 / 4;
            grid-row: 1 / 2;
            display: flex;
            flex-direction: column;
            .spc-preferred-dealer-message {
                flex: 0 0 auto;
                font-family: $f1-font-regular;
                font-size: 9px;
                background-color: #102b4e;
                border: 2px solid #102b4e;
                padding: 5px;
                margin-bottom: 10px;
                border-radius: 5px;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: left;
            }
            .spc-desktop-search-input-autocomplete {
                padding-bottom: 25px;
                .auto-complete-container {
                    .auto-complete-label-container {
                        display: none;
                    }
                    .autoCompleteText {
                        width: 290px;
                        button {
                            width: 25px;
                        }
                    }
                    .pc-input-text-error {
                        position: relative;
                        bottom: 23px;
                        border: solid 1px #d62d0a;
                        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1),
                            0 20px 20px 0 rgba(0, 0, 0, 0.1);
                    }
                }
                &.disabled {
                    pointer-events: none;
                    opacity: 0.5;
                }
                .osb-error-icon {
                    height: 20px;
                    width: 20px;
                    position: relative;
                    bottom: 23px;
                }

                .spc-input-error {
                    color: red;
                    font-size: 12px;
                    margin-top: -17px;
                }
            }
            .spc-enable-location-link {
                color: #00095b;
                font-family: $f1-font-regular;
                font-size: 16px;
                text-decoration: underline;
                cursor: pointer;
                padding-bottom: 10px;
            }
            .spc-desktop-search-input-use-my-location {
                .osb-use-location button {
                    background-color: #f4f4f4;
                }
                .spc-desktop-search-input-link-text {
                    color: #00095b;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    letter-spacing: 1px;
                    line-height: 24px;
                }
                .spc-desktop-search-input-link-icon {
                    font-size: 24px;
                    margin-right: 2%;
                }
            }
        }

        .spc-desktop-search-input-button-container {
            grid-column: 4 / 5;
            grid-row: 1 / 2;
            justify-self: end;
        }
    }
}
