@import '../../../../styles/main.scss';

.dealer-service-container {
    margin: 20px 0;
    .dealer-service-info {
        display: flex;
        margin: 20px 0 0 0;
        .service-name {
            color: #00095b;
            font-family: $f1-font-regular;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 18px;
            width: 60%;
            text-transform: uppercase;
            span {
                font-size: 20px;
                margin-left: 10px;
            }

            .tooltip__infoIcon {
                &.dark {
                    background-color: $fds-color-white;
                }
            }
        }

        .service-price {
            color: #00095b;
            font-family: $f1-font-regular;
            font-size: 12px;
            letter-spacing: 2.35px;
            line-height: 14.4px;
            text-align: right;
            width: 40%;
        }
    }
}
.dealer-convenience-option {
    .fmc-accordion {
        .fmc-accordion__panel {
            border-top: none;
            border-bottom: none;
            .fmc-accordion__body {
                max-height: 417px;
                overflow-y: scroll;
                background-color: $fds-color-surface;
                border-bottom: 1px solid $fds-color-border-gray;
                &::-webkit-scrollbar {
                    width: 5px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: $fds-color-border-gray;
                }

                .fmc-accordion__content {
                    padding: 5px;
                    .fmc-type--body1 {
                        text-transform: uppercase;
                        color: #00095b;
                        font-family: $f1-font-regular;
                        font-size: 12px;
                        font-weight: bold;
                        letter-spacing: 2px;
                        line-height: 18px;
                        .dealer-tile-service {
                            color: #00095b;
                            font-family: $f1-font-regular;
                            font-size: 12px;
                            font-weight: bold;
                            letter-spacing: 2px;
                            line-height: 18px;
                            display: flex;
                            padding: 5px 0;
                            .dealer-tile-service-info {
                                display: block;
                                justify-content: space-between;
                                align-items: center;
                                .info-icon-tooltip {
                                    margin-left: 0;
                                }
                                .dealer-tile-service-name {
                                    max-width: 200px;
                                    word-wrap: break-word;
                                    margin-right: 10px;
                                }
                                .dealer-tile-service-info-icon {
                                    font-size: 24px;
                                }
                                .tooltip__infoIcon {
                                    &.dark {
                                        background-color: $fds-color-surface;
                                    }
                                }
                            }
                            .dealer-tile-service-price {
                                margin-left: auto;
                            }
                        }
                        .dealer-tile-line {
                            border-bottom: 1px solid black;
                        }
                    }
                }
            }

            .fmc-accordion__button {
                border-bottom: none;
                height: 40px;
                display: flex;
                align-items: center;
                position: relative;
                .fmc-accordion__button-title {
                    text-transform: uppercase;
                    margin: 0;
                    padding: 0;
                    color: #ffffff;
                    font-family: $f1-font-regular;
                    font-size: 12px;
                    letter-spacing: 1px;
                    line-height: 18px;
                }
                .fmc-accordion__button-expand {
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                }
            }
        }
        .fmc-accordion--expanded {
            .fmc-accordion__button {
                border-bottom: 0.5px solid $fds-color-dk-gray;
            }
        }
    }
}
