@import '../../../styles/main.scss';
.secondary-nav-main-wrapper {
    padding: 16px 0;
    top: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(5px);

    &.expanded-wrapper,
    &.is-expanded {
        background-color: rgba(255, 255, 255, 1);
    }

    @include mobile {
        padding: 16px 16px;
    }

    .title-and-control-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0;
        cursor: pointer;
        padding: 0 56px;
        @include mobile {
            padding: 0;
        }
        .my-shortcuts {
            color: $fds-color-black;
            font-family: $f1-font-medium;
            font-size: 16px;
            font-style: normal;
            line-height: 24px;
            @include mobile {
                color: $fm-breadcrumb-blue;
            }
        }
        .view-all__toggle-container {
            display: flex;
            .view-all__label {
                color: $fm-breadcrumb-blue;
                text-align: center;
                font-family: $f1-font-regular;
                font-size: 14px;
                font-style: normal;
                line-height: 20px;
                display: flex;
                align-items: center;
                @include mobile {
                    display: none;
                }
            }
            .caret-down {
                margin-left: 8px;
                transition: transform 0.3s linear;
                &.rotate {
                    transform: rotate(180deg) translateX(-2px);
                }
            }
        }
    }

    .expanded {
        &-container {
            position: absolute;
            top: pToR(56);
            width: 100%;
            background-color: white;
            display: grid;
            grid-template-rows: 0fr;
            transition: all 300ms ease-out;
            overflow: hidden;

            @include mobile {
                max-height: 0;
                left: 0;
            }

            @include tablet {
                padding: 0 pToR(50);
                max-width: pToR(1440);
            }

            &.is-expanded {
                grid-template-rows: 1fr;
                transition: all 300ms ease-in;

                @include mobile {
                    max-height: calc(100vh - 56px);
                    overflow-y: auto;
                    padding: 10px 16px 20px;
                }

                @include tablet {
                    padding: 0 pToR(50) pToR(54);
                }

                .expanded-content {
                    @include mobile {
                        height: 100vh;
                        flex-direction: column;
                        overflow-y: auto;
                        gap: 24px;
                        scrollbar-width: none;
                    }
                }
            }

            @include mobile {
                background-color: white;
            }
        }

        &-content {
            display: grid;
            grid-template-columns: 1fr;
            grid-auto-rows: min-content;
            width: 100%;
            overflow: hidden;

            @include tablet {
                grid-template-columns: repeat(3, minmax(214px, 320px));
                transform: translateY(14px);
                padding: 0 pToR(6);
                column-gap: pToR(16);
                row-gap: pToR(32);
            }
        }
    }
    .horizontal-divider {
        border: none;
        border-top: 1px solid $lightgrey;
        box-shadow: none;
        @include desktop {
            display: none;
        }
        @include tablet {
            display: none;
        }
    }
    .profile-info {
        @media (min-width: 450px) and (max-width: 767px) {
            width: fit-content;
        }
        @media (min-width: 768px) and (max-width: 768px) {
            display: none;
        }
        display: flex;
        padding: 16px 12px;
        align-items: center;
        border-radius: 12px;
        border: 1px solid $fds-color-lt-gray-b;
        background: $banner-lightgrey-background;
        .first-name {
            @media (min-width: 450px) and (max-width: 767px) {
                flex: unset;
            }
            flex: 1 0 0;
            color: $fds-color-black;
            font-family: $f1-font-medium;
            font-size: 16px;
            font-style: normal;
            line-height: 24px;
            padding: 0 24px 0 8px;
        }
    }
}
