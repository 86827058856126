@import '../../../../styles/main.scss';

.service-history-add-services-performed-block {
    display: flex;
    flex-direction: column;

    fieldset {
        border: none;
    }

    @include mobile {
        input[type='text'] {
            margin: 0 0 0 1px;
        }
    }
}

.service-history-form-main-headers {
    margin-bottom: -13px;
}

.service-history-form-name {
    font-family: $f1-font-light;
    font-size: pToR(24);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    color: #4d4d4d;
    @include mobile {
        padding-left: 7%;
    }
}
.service-history-form-headers {
    @extend .service-history-form-name;
    margin: 4% 0 3% 0;
    @include mobile {
        margin: 13% 0 10% 0;
        font-size: 1.25rem;
    }
}

label.service-history-form-headers {
    display: block;
}

.service-history-form-checkbox-label {
    width: 10px;
    height: 10px;
    background-color: $fds-color-primary;
}
.service-history-form-checkbox-category-name {
    @extend .service-history-add-services-performed-block;
    font-size: pToR(14);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    color: $fds-color-primary;
    text-transform: uppercase;
    margin-top: pToR(40);
    width: pToR(353);
    height: pToR(15);
    font-family: $f1-font-regular;
}

.service-history-form-horizontal-line {
    box-shadow: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin: pToR(16) 0 0 0;
    width: pToR(220);
    height: pToR(1);
    @include mobile {
        width: pToR(280);
        align-items: center;
    }
}

.total-cost-disclaimer {
    height: 16px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $fds-color-md-gray;
    padding-top: pToR(15);
}

.dropdown-label-service-location {
    font-family: $f1-font-regular;
    height: 12px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    color: $fds-color-dk-gray;
    padding-bottom: pToR(20);
}

.brand-ford .service-history-records-section .dropdown-container-wrapper {
    padding-top: 5px;
    label.label {
        display: none;
    }
    .dropdown {
        .error-message {
            top: 58px;
            color: $error-message-text;
        }
    }
}

.dropdown-items-panel {
    background-color: white !important;
    color: $fds-color-primary !important;
}
.service-history-form-add-service-input-fields {
    .dropdown .dropdown-button {
        background-color: white;
        color: $brand-dropdown-button;
        font-size: 1rem;
    }
    :focus {
        outline: auto;
    }

    @extend .service-history-add-services-performed-block;
    @include mobile {
        margin: 0 7%;

        .check-box-spacing {
            margin-top: 4%;
        }
    }
    .input-field-container.error {
        input[value=''] + label {
            color: $fds-color-dk-gray;
        }
        &:focus-within {
            input[value=''] + label {
                color: $error-message-text;
            }
        }
    }
}
.add-fields div.input-field-container.fds-style.error {
    .error-icon,
    .success-icon {
        display: inline-block;
    }
}

.check-box-spacing {
    margin-bottom: 32px;
}

.add-fields {
    width: 320px;

    @include mobile {
        width: auto;

        div.input-field-container.fds-style {
            @include mobile {
                padding-left: 0;

                .input-label {
                    top: -29px;
                }
                input[value=''] + label {
                    top: 15px;
                }
                .error-icon {
                    top: -25px;
                    left: 0;
                    vertical-align: top;

                    &.empty {
                        top: -10px;
                    }
                }
                input {
                    width: 100%;
                    border-radius: 3px;
                }
                .success-icon {
                    @include mobile {
                        top: -25px;
                        vertical-align: top;
                    }
                }
                &:focus-within {
                    label.input-label {
                        top: -29px;
                        left: 0;
                    }
                    .error-icon.empty {
                        top: -25px;
                    }
                }
            }
        }
        div.fds-style + div.error-message {
            @include mobile {
                top: 5px;
                left: 0;
                white-space: normal;
            }
        }
    }

    div.error-message {
        display: none;
    }

    div.input-field-container.fds-style:not(:first-child) {
        margin-top: 45px;

        @include mobile {
            margin-top: 40px;
        }
    }

    .error-icon {
        height: 15px;
    }

    .input-label {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: pToR(20) 0 pToR(13) 0;
    }

    .input-field,
    .input-field-error {
        width: 320px;
        box-sizing: border-box;

        @include mobile {
            width: auto;
        }
    }

    .error-message {
        font-family: $f1-font-regular;
        color: $error-text;

        @include mobile {
            padding: 0;
        }
    }

    .dropdown .error-icon {
        position: absolute;
        right: 0;
        top: -83px;
    }
}

.service-history-form-services-performed-fields {
    @extend .service-history-form-add-service-input-fields;
    display: flex;
    flex-direction: column;
}

.service-history-form-notes {
    @extend .service-history-form-add-service-input-fields;
    margin-right: pToR(66);
    @include mobile {
        margin-right: pToR(25);
    }
}

.service-history-form-disclaimer {
    display: flex;
    align-content: center;
    position: relative;

    .disclaimer-checkbox {
        align-self: flex-start;
        margin-top: 5px;
    }

    .disclaimer-checkbox:checked {
        &:before {
            color: $fds-color-primary;
            border: 2px solid $fds-color-primary;
        }
    }
}

.service-history-form-disclaimer-label {
    display: inline-block;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    color: $fds-color-dk-gray;
    padding-left: 20px;
}
.disclaimer-error {
    width: 16px;
    height: 16px;
}
.disclaimer-error-label {
    color: $fds-color-red;
    font-family: $f1-font-regular;
    font-size: 12px;
    line-height: 18px;
    display: inline-block;
    margin-left: 20px;
}
.privacy-link {
    padding-left: 36px;
    padding-top: 16px;

    a {
        color: $fds-color-primary;
        font-family: $f1-font-regular;
        font-size: 16px;
        line-height: 26px;
    }
}
.service-history-form-notes-input {
    font-family: $f1-font-regular;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    color: $fds-color-primary;
    padding: pToR(12) pToR(16) 0;
    margin-bottom: pToR(55);
}

.service-history-form-services-performed {
    @extend .service-history-form-name;
    margin-bottom: pToR(-15);
    margin-top: 4%;
    @include mobile {
        margin-top: 13%;
        font-size: 1.25rem;
    }
}
.service-history-form-inspections-performed {
    margin-bottom: 2%;
    @include mobile {
        margin-bottom: 5%;
    }
}

.service-history-form-panel-button {
    display: flex;
    margin-top: pToR(40);
    @include mobile {
        margin-left: 14%;
        margin-bottom: 10%;
    }
}

.service-history-form-panel-button-save {
    margin-right: 0.5%;

    .primary-button.disabled {
        cursor: not-allowed;
    }
}

.dropdown-button {
    font-size: 1rem;
    @include mobile {
        width: 17.5rem;
    }

    .error-message ~ .dropdown-button {
        border-color: $error-text;
    }
}

.dropdown-button.dropdown .error-message {
    top: 58px;
    color: $fds-color-red;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 320px;

    @include mobile {
        width: inherit;
    }
}

.service-history-form-my-preferred-dealer {
    color: $fds-color-primary;
    @include mobile {
        margin-left: 5%;
    }
}

.service-history-form-error-message {
    color: $error-message-text;
    font-weight: normal;
    padding-top: 16px;
    display: flex;
}

.services-performed-error-message-show {
    color: $error-message-text;
    font-weight: normal;
    display: block;
    margin: 3% 0 0;
    @include mobile {
        margin: 10% 0 0 7%;
    }
}

.services-performed-error-message-hide {
    display: none;
}

.service-history-disclaimer-error-message-hide {
    display: none;
}

.service-history-form-add-service-error-modal {
    width: pToR(1030) !important;
    height: pToR(447) !important;
    @include mobile {
        width: 100% !important;
        height: pToR(520) !important;
        left: 0;
    }
}
.service-history-form-add-service-error-modal-styling {
    text-align: center;
    font-family: $f1-font-light;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    color: $fds-color-dk-gray;
}
.service-history-form-add-service-error-modal-header {
    padding-top: pToR(96);
    padding-bottom: pToR(16);
    font-family: $f1-font-light;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    text-align: center;
    color: $fds-color-dk-gray;
    @include mobile {
        padding-top: 4rem;
    }
}
.service-history-form-panel-item {
    padding: 0 2% 1% 2%;
    text-align: left;
    display: flex;
    @include mobile {
        flex-direction: column-reverse;
    }
}
.service-history-form-panel-add-service-error-modal-confirmation-button {
    padding-top: pToR(48);
    justify-content: center;
    @extend .service-history-form-panel-item;
    @include mobile {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .primary-button {
        padding: 0 1rem !important;
    }
}
.service-history-header {
    margin-bottom: 40px;
    @include mobile {
        margin-bottom: 20px;
        margin-left: 5%;
    }
}
.service-history-header .service-history-item-header {
    color: $fds-color-dk-gray;
    font-family: $f1-font-light;
    font-size: 24px;
    line-height: 34px;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 0;
}
.service-history-header .service-history-item {
    font-family: $f1-font-regular;
    color: $dropdown-text;
    font-size: 1rem;
    text-align: left;
    padding-top: 1%;
    @include mobile {
        width: 100%;
        font-size: 1rem;
    }
}
.brand-ford .total-cost-disclaimer {
    margin-top: 8px;
    margin-bottom: 15px;

    @include mobile {
        margin-top: 0;
        padding-top: 10px;
    }
}
.brand-ford .add-fields label.input-label.service-location-label,
.brand-ford .add-fields label.input-label.business-name-label {
    visibility: hidden;
    transition: 0.2s;
    justify-content: flex-start;
    margin-top: 10px;

    .success-icon,
    .error-icon {
        visibility: hidden;
        display: inline-block;
        height: 14px;
        width: 14px;
        margin-left: 5px;
        margin-bottom: 5px;
    }

    &.dropdown-selected {
        visibility: visible;

        .success-icon,
        .error-icon {
            visibility: visible;
        }
    }
}

.brand-ford .add-fields .service-history-form-service-location-dropdown,
.brand-ford .add-fields .service-history-form-business-name-dropdown {
    &.valid button.dropdown-button {
        border: 1px solid $fds-color-green;
    }
    .error-message .error-icon {
        display: none;
    }
}
.brand-ford {
    span.error-message {
        position: relative;
        top: 20px;
        @include mobile {
            top: 5px;
            left: 0;
            white-space: normal;
        }
    }
}
@import './service-history-form.lincoln.scss';
