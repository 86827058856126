@import '../../../styles/main.scss';

.service-pc-container {
    background-color: #f4f4f4;
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
    .pc-top-banner {
        position: sticky;
        top: 0;
        z-index: 999 !important;
        .osb-banner {
            height: 68px;
            @include mobile {
                width: 100%;
                height: 60px;
            }
            .leftSide {
                background-color: #102b4e;
                @include desktop {
                    width: 100%;
                }
                @include mobile {
                    padding: 0;
                    text-align: center;
                }
                span {
                    font-weight: normal;
                }
            }
        }
    }

    .service-error {
        width: 100%;
        font-size: 12px;
        color: #d62d0a;
        font-family: $f1-font-regular;
        line-height: 16px;
        padding: 40px 0 0 10%;

        @media screen and (min-width: 1700px) {
            padding: 40px 0 0 7%;
        }

        .osb-error-icon {
            width: 20px;
            float: initial;
        }
    }

    .pc-bottom-banner {
        font-family: $f1-font-light;
        height: 210px;
        background-color: #102b4e;
        z-index: 10;
        color: #ffffff;
        @include mobile {
            height: 241px;
        }
        .pc-bottom-banner-text {
            font-size: 20px;
            line-height: 26px;
            text-align: center;
            padding-top: 75px;
            padding-bottom: 15px;
            @include mobile {
                font-size: 11px;
                padding: 40px 19px 20px 19px;
            }
        }
        .pc-button-wrapper {
            margin: auto;
            display: flex;
            justify-content: space-between;
            width: 550px;
            @include mobile {
                width: 100%;
                flex-direction: column;
            }
            .osb-secondary-button ul li {
                color: #ffffff;
            }
            .fds-chevron .fds-arrow.filled {
                color: #102b4e;
            }
            .book-a-service {
                display: flex;
                justify-content: center;
            }
            .find-a-dealer {
                display: flex;
                justify-content: center;
                @include mobile {
                    padding-top: 20px;
                    padding-bottom: 25px;
                }
            }
        }
    }
}
