@import '../../../styles/print';

.service-history-section,
.fds-chevron-wrap,
.button-wrapper,
.find-it-fast,
.search-bar,
.fordMainNavigation,
.disclosures,
.lincoln-na-header,
.disclosures-link-section {
    @include print {
        display: none;
    }
}
.LPMcontainer.LPMoverlay,
.lp_desktop,
.header {
    @include print {
        display: none !important;
    }
}
.service-history-title {
    @include print {
        margin-top: 30px;
    }
}

.print-divider {
    width: 90%;
    border-top: 3px dashed $lightgrey;
    margin-top: 20px;
    margin-bottom: 20px;
}
.print-service-records {
    display: block;
    @include print {
        position: relative;
        float: none;
        width: 100%;
        display: block;
        background-color: $white;
    }
}
.service-history-records-section {
    @include print {
        display: none;
    }
}
.service-history-print {
    page-break-after: always;
    float: none;
    margin-top: 20px;
    margin-left: 75px;
    .service-download-history-vin {
        margin-top: 5px;
    }

    .print-row {
        display: flex;
        flex-direction: row;
        padding-bottom: 10px;
        font-size: 12px;
        width: 100%;
    }

    .print-col {
        padding-right: 50px;
        width: 100%;
    }

    label {
        font-family: $f1-font-light;
        color: $dark-blue;
        text-transform: uppercase;
        line-height: 1.875rem;
        padding: 0;
        margin: 0;
    }

    .check-list {
        list-style: none;
        .check-img {
            margin-right: 15px;
            width: 10px;
        }

        li {
            padding: 10px 0;
        }
    }

    .operations {
        max-width: 250px;
        span {
            display: inline;
        }
    }
}
