@import '../../../../styles/main.scss';

.review-service-price-container {
    .review-header {
        padding-top: 50px;
        color: #4d4d4d;
        font-family: $f1-font-regular;
        font-size: 34px;
        letter-spacing: 4px;
        line-height: 44px;
        text-align: center;
        @include mobile {
            font-size: 20px;
            padding: 30px 25px 0 25px;
            letter-spacing: 3px;
            line-height: 30px;
        }
        @include tablet {
            padding: 30px 20px 0 20px;
        }
    }
    .service-error {
        width: 100%;
        font-size: 12px;
        color: #d62d0a;
        font-family: $f1-font-regular;
        letter-spacing: 1px;
        line-height: 16px;
        padding: 40px 0 0 10%;

        @media screen and (min-width: 1700px) {
            padding: 40px 0 0 7%;
        }

        .osb-error-icon {
            width: 20px;
            float: initial;
        }
    }

    .review-text {
        color: #4d4d4d;
        font-family: $f1-font-regular;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 26px;
        padding: 35px 10% 0 11.5%;
        display: flex;
        justify-content: center;

        @media screen and (min-width: 1700px) {
            padding: 35px 15% 0 15.5%;
        }
        .sign-in {
            display: inline-block;
            margin-left: 5px;
            color: #102b4e;
            text-decoration: underline;
            cursor: pointer;
        }
        @include mobile {
            padding: 15px 25px 0 25px;
            text-align: center;
            display: block;
        }
    }

    .vehicle-input-container {
        margin: 25px 10% 80px 11%;
        display: grid;
        grid-template-columns: 25% 25% 25% 18%;
        grid-column-gap: 3%;
        height: auto;
        justify-content: center;

        @media screen and (min-width: 1700px) {
            margin: 25px 15% 80px 15%;
        }

        @include mobile {
            display: flex;
            padding: 30px 25px 0 25px;
            margin: 0;
            flex-direction: column;
            height: auto;
        }
        @media screen and (max-width: 1245px) and (min-width: 768px) {
            display: flex;
            padding: 0px;
            //margin: 0 auto;
            flex-direction: column;
            height: auto;
        }

        .input-regno-vin {
            margin-top: 0;
            display: flex;
            flex-direction: column;
            @include tablet {
                width: 100%;
            }
            @include mobile {
                flex-direction: row;
                .pc-input-wrapper {
                    flex: 1;
                }
            }
            .vin-reg-info-icon {
                cursor: pointer;
                padding-top: 14px;
                color: #102b4e;
                font-family: $f1-font-regular;
                @include mobile {
                    padding-top: 36px;
                }
                .find-vin-icon {
                    font-size: 24px;
                    margin-left: 2%;
                }
                .find-vin-text {
                    text-decoration: underline;
                    font-size: 16px;
                    line-height: 26px;
                    letter-spacing: 1px;
                    @include mobile {
                        display: none;
                    }
                }
            }
        }

        .input-regno-vin-authflow {
            .pc-dropdown-vin-wrapper {
                margin-top: -2px;
                .choose-vehicle-label {
                    font-family: $f1-font-regular;
                    font-size: 12px;
                    letter-spacing: 1px;
                    line-height: 12px;
                    color: #4d4d4d;
                    margin-bottom: 15px;
                }
                .dropdown-container-wrapper {
                    padding-top: 0 !important;
                    .dropdown {
                        color: #102b4e !important;
                        min-width: 15.5rem;

                        .dropdown-container {
                            .dropdown-items-panel {
                                max-height: 150px !important;
                            }
                        }
                    }
                }
            }
            .vinreg-auth-wrapper {
                display: flex;
                .pc-input-wrapper {
                    flex: 1;
                }
                .vinreg-auth-input-text {
                    width: 220px;
                    height: 52px;
                    padding: 12px 16px;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 1px;
                    color: #102b4e;
                    border: solid 1px #6e6e6e;
                    border-radius: 3px;
                    background-color: #f4f4f4;
                    @include mobile {
                        width: 100%;
                    }
                }
                .auth-vin-icon {
                    cursor: pointer;
                    color: #102b4e;
                    font-size: 28px;
                    padding-top: 32px;
                    padding-left: 8px;
                }
                .pc-input-error {
                    width: 220px;
                    @include mobile {
                        width: 100%;
                    }
                }
            }
            .choose-vehicle-link {
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 26px;
                letter-spacing: 1px;
                color: #102b4e;
                margin-top: 20px;
                text-decoration: underline;
                cursor: pointer;
            }
        }
        .input-mileage {
            @media screen and (max-width: 1245px) {
                margin: 30px 0 0 0;
            }
        }

        .btn-single-text-margin {
            margin-top: 14%;
        }

        .btn-double-text-margin {
            margin-top: 11%;
        }

        .btn-get-quote {
            .primary-button {
                max-width: 180px;
                justify-content: center;
                padding: 0 15px;
                .button-label.no-chevron {
                    white-space: break-spaces;
                    margin-top: 5%;
                    margin-bottom: 5%;
                }
            }
            .button-label {
                font-size: 18px;
                font-family: $f1-font-regular;
                line-height: 1.25rem;
                letter-spacing: 1px;
                text-align: center;
                color: #ffffff;
            }

            @include mobile {
                margin: 50px 0 50px 0;
                display: flex;
                justify-content: center;
            }
            @media screen and (max-width: 1245px) and (min-width: 768px) {
                margin-left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .input-location {
            width: 100%;
            @include mobile {
                width: 100%;
            }
            @media screen and (max-width: 1245px) {
                margin: 30px 0 0 0;
            }

            .pc-autocomplete {
                z-index: 99999;

                .auto-complete-container .auto-complete-label-container {
                    display: none;
                }
                .auto-complete-container {
                    @include mobile {
                        margin: 18px 0 0 0;
                    }
                    .autoCompleteText {
                        background-color: #f4f4f4;
                        button {
                            background-color: #f4f4f4;
                            padding-left: 16px;
                            color: #102b4e;
                        }
                    }
                    .pc-input-text-error {
                        border: solid 1px #d62d0a;
                        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1),
                            0 20px 20px 0 rgba(0, 0, 0, 0.1);
                    }
                    input {
                        width: 80%;
                        background-color: #f4f4f4;
                        padding: 12px 16px;
                        color: #102b4e;
                    }
                    li {
                        color: #102b4e;
                    }
                }
                .auto-complete-container .auto-complete-label-container {
                    .osb-error-icon {
                        width: 20px;
                        margin-bottom: 5px;
                    }
                }
            }
            .pc-use-location {
                margin-top: 25px;
                .osb-use-location {
                    margin: 0 !important;
                    @include mobile {
                        margin: 26px 0 0 0;
                        width: 100% !important;
                    }
                    button {
                        background-color: #f4f4f4 !important;
                    }
                }
            }
        }

        .input-location_onchange {
            .pc-autocomplete {
                .auto-complete-container {
                    .autoCompleteText {
                        background-color: white;
                        button {
                            background-color: white;
                        }
                    }
                    input {
                        background-color: white;
                    }
                }
            }
        }

        .vinreg-input-text {
            width: 270px;
            height: 52px;
            padding: 12px 16px;
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 1px;
            color: #102b4e;
            border: solid 1px #6e6e6e;
            border-radius: 3px;
            background-color: #f4f4f4;
            @include tablet {
                width: 100%;
            }
            @include mobile {
                width: 95%;
                margin-top: 12px;
            }
        }

        .mileage-input-text {
            width: 270px;
            height: 52px;
            padding: 12px 16px;
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 1px;
            color: #102b4e;
            border: solid 1px #6e6e6e;
            border-radius: 3px;
            background-color: #f4f4f4;
            @include tablet {
                width: 100%;
            }
            @include mobile {
                width: 100%;
                margin-top: 12px;
            }
        }

        .vinreg-input-text-error,
        .mileage-input-text-error {
            border: solid 1px #d62d0a;
            box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1),
                0 20px 20px 0 rgba(0, 0, 0, 0.1);
        }

        .bg_input_color_onchange {
            background-color: white !important;
        }
    }
    .find-my-vin-popup-container {
        .modal {
            @include mobile {
                padding: 0 !important;
            }
        }
    }
}

.find-my-vin {
    margin-top: 20px;
    width: fit-content;

    button {
        line-height: 3rem;
        margin: 0;
        display: flex;
        text-align: left;
        width: 100%;
        border: none;
        background-color: #ffffff !important;
        cursor: pointer;

        @include tablet-portrait {
            text-align: center;
            justify-content: center;
        }

        img {
            height: 20px;

            @include tablet-portrait {
                margin-top: 3px;
            }
        }

        div {
            margin: 3px 0 0 8px;
            font-size: 14px;
            line-height: 1.43;
            letter-spacing: 1px;
            color: #00095b;
            font-family: $f1-font-regular;

            @include tablet-portrait {
                font-size: 16px;
                line-height: 1.63;
            }
        }

        .icon-navigation {
            content: \e687;
            vertical-align: middle;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    hr {
        box-shadow: none;
    }
}
