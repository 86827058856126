.brand-ford {
    .subcategory-component-box-5050 {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        margin-bottom: 70px;
        margin-top: 20px;
        color: $fds-color-dk-gray;
        text-align: left;
        background-color: #ffffff;
        box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1),
            0 10px 10px 0 rgba(0, 0, 0, 0.1);

        .subcategory-component-box-5050__content-wrapper {
            display: flex;
            flex-direction: column;

            .subcategory-component-box-5050__subtitle {
                margin: 25px 30px 10px 30px;
                font-size: pToR(48);
                font-family: $f1-font-light;
                line-height: 58px;
                text-transform: uppercase;
                text-align: center;
            }

            .subcategory-component-box-5050__body {
                margin: 0 30px 15px 30px;
                font-family: $f1-font-regular;
                align-self: center;
                font-size: pToR(16);
                line-height: 26px;
                text-align: center;
            }

            .subcategory-component-box-5050__primary-button {
                align-content: center;
                margin: 25px auto 40px auto;
                bottom: 15px;
                left: 0;
                right: 0;
            }
        }

        .subcategory-component-box-5050__image {
            img {
                height: auto;
                width: 100%;
            }
        }

        @media only screen and (min-width: 768px) and (max-width: 992px) {
            max-width: 819px;
            flex-direction: row;
            .subcategory-component-box-5050__content-wrapper {
                width: 52%;

                .subcategory-component-box-5050__subtitle {
                    margin: 50px 30px 10px 30px;
                }
            }
            .subcategory-component-box-5050__image {
                width: 50%;
                img {
                    height: 100%;
                }
            }
        }
        @media only screen and (min-width: 993px) and (max-width: 1199px) {
            flex-direction: column-reverse;
            .subcategory-component-box-5050__content-wrapper {
                .subcategory-component-box-5050__subtitle {
                    margin: 50px 30px 10px 30px;
                }
            }
        }
        @media only screen and (min-width: 1200px) {
            min-height: 400px;
            max-height: 540px;
            flex-direction: row;
            margin-bottom: 70px;
            margin-top: 85px;
            .subcategory-component-box-5050__content-wrapper {
                width: 50%;

                .subcategory-component-box-5050__subtitle {
                    padding-top: 10px;
                    margin: 34px 52px 15px 54px;
                }

                .subcategory-component-box-5050__body {
                    margin: 0 63px 25px 64px;
                }
            }
            .subcategory-component-box-5050__image {
                width: 50%;
                img {
                    height: 100%;
                }
            }
        }
    }
}
