@import '../../../../styles/print';
.print-page {
    position: relative;
    height: 1120px;
    border: 0px solid;
}
.print-page-last-record {
    height: 1100px;
}
.print-header {
    @include print {
        display: block;
    }

    .print-header-ford-logo {
        content: url('../../../../assets/ford-logo.svg');
        margin-top: 40px;
        width: 120px;
        margin-left: 75px;
    }
}
.service-download-history-title {
    font-family: $f1-font-light;
    margin-top: 35px;
    margin-left: 60px;
    font-size: 16px;
}
.service-download-history-header {
    display: flex;
    padding: 15px;
    margin-top: 30px;
    margin-left: 75px;
    width: 80%;
    border-top: 2px solid $fds-color-lt-gray;
    border-bottom: 2px solid $fds-color-lt-gray;
    font-size: 12px;
    height: 100%;

    .service-history-accordion-details-header,
    .service-download-history-accordion-details-item {
        font-weight: bold;
        font-size: 12px;
    }
}
.service-download-history-accordion-title-details-left {
    width: 14%;
    text-align: left;
    margin-left: -10px;
}
.service-download-history-accordion-title-details-medium {
    width: 68%;
    text-align: left;
}
.service-download-history-accordion-title-details-right {
    width: 20%;
    text-align: left;
}
.pdf-ford-col {
    .pdf-ford-col-logo {
        content: url('../../../../assets/ford-logo.svg');
        &.hide {
            display: none;
        }
        position: relative;
        display: inline;
        margin-top: 3px;
    }

    .pdf-ford-col-txt {
        display: inline;
        position: absolute;
        margin-left: 10px;
        margin-top: 5px;
        font-size: 9px;
        color: $dark-blue;
        &.hide {
            display: none;
        }
    }
}
.download-service-header {
    margin-top: 30px;
}
.print-footer {
    position: relative;
    @include print {
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 70px;
        box-shadow: inset 0 0 0 1000px $fds-color-lt-gray;
        .pdf-ford-col-footer {
            .pdf-ford-col-logo-footer {
                content: url('../../../../assets/ford-logo.svg');
                display: inline;
                position: relative;
                width: 50px;
                height: 70px;
                top: 28px;
                margin-left: 75px;
            }
            .pdf-ford-col-txt-footer {
                display: inline;
                position: absolute;
                margin-left: 10px;
                margin-top: 30px;
                font-size: 11px;
                font-weight: bold;
                color: $dark-blue;
                &.hide {
                    display: none !important;
                }
            }
        }
    }
}
.print-page-number {
    @include print {
        position: absolute;
        z-index: 99999;
        bottom: 20px;
        width: 100px;
        margin-left: 75%;
    }
}
.notification-message {
    @include print {
        display: none;
    }
}
.support-fds-wrapper.service-history-wrapper {
    @include print {
        display: none;
    }
}
