@import '../../../styles/main.scss';

.site-search-container {
    font-size: x-large;
    margin: 0 0.5rem;
    padding-top: 0.4rem;

    @include desktop {
        margin: 0 0.5rem;
    }

    .search-icon {
        font-size: medium;
        font-weight: bold;
    }

    .search-text {
        display: none;
        font-family: $f1-font-regular;
        position: relative;

        @include desktop {
            display: initial;
            left: 0.5rem;
        }
    }

    .input-container {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2000;
        width: 100%;
        height: 100%;

        .input {
            background-color: #f2f2f2;
            display: flex;
            align-items: center;
            min-height: pToR(175);
            text-align: center;
            padding: 0 pToR(10);

            @include tablet {
                padding: 0 pToR(100);
            }

            .close {
                position: absolute;
                top: 1rem;
                right: 1rem;
                color: gray;
                font-size: xxx-large;
            }
        }

        .overlay {
            background-color: black;
            opacity: 0.7;
            height: 100%;
        }
    }
}
