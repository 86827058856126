@import '../../../../styles/main.scss';

.brand-lincoln {
    .service-history-records-section {
        .service-history-header {
            .service-history-item,
            .service-history-item-header {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
            }
        }

        .service-history-form-services-performed,
        .service-history-form-name {
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
        }
    }

    .add-fields .input-label {
        font-size: 16px;
        top: 43px;
        left: 14px;
        z-index: 1;
    }

    .service-history-form-add-service-input-fields {
        .input-field-container {
            input {
                @include mobile {
                    top: 30px;
                }
            }
        }
        .checkbox-label {
            padding-top: 4px;
        }
        .service-location-label,
        .business-name-label {
            top: 68px;
        }
        .dropdown {
            .dropdown-button {
                background-color: $brand-while-smoke;
                height: 60px;
                padding: 0 14px 0;
                .dropdown-current-item {
                    padding: 29px 0 0 0;

                    .fds-chevron-wrap {
                        top: -12px;
                    }
                }
            }

            .dropdown-container .dropdown-items-panel {
                margin-top: 10px;

                .dropdown-item {
                    color: $brand-white;
                    background-color: $brand-dropdown-list;

                    &.selected-item {
                        background-color: $brand-dropdown-selected;
                        &:before {
                            content: '\2714';
                            display: inline-block;
                            width: 20px;
                            margin-left: -15px;
                        }
                    }
                }
            }

            .error-message {
                top: 63px;
                color: $brand-light-error;
            }
        }
        .input-field-container.error {
            input[value=''] + label {
                color: $primary-graphite;
            }
            &:focus-within {
                input[value=''] + label {
                    color: $brand-light-error;
                }
            }
        }
        .error-message {
            font-family: $proxima-nova-regular;
            color: $brand-light-error;
        }
    }

    .service-history-form-panel-button-cancel {
        @include mobile {
            display: flex;
            align-items: center;
        }
    }

    .privacy-link a {
        color: $brand-secondary;
        font-family: $proxima-nova-semi-bold;
        text-decoration: none;
        border-bottom: 2px solid $brand-border;
        letter-spacing: normal;

        &:hover,
        &:focus {
            color: $brand-secondary;
            border-bottom: 2px solid $brand-secondary;
        }
    }

    .service-location-label,
    .business-name-label {
        &.dropdown-selected {
            top: 55px;
            font-size: 13px;
            z-index: 1;
            left: 14px;
        }
    }

    .service-history-add-services-performed-block {
        display: flex;
        flex-direction: column;
        font-family: $proxima-nova-regular;
    }

    .disclaimer-error-label {
        color: $brand-light-error;
        font-family: $proxima-nova-regular;
    }

    .service-history-form-error-message {
        @include mobile {
            margin-left: 7%;
        }
    }

    .disclaimer-checkbox {
        width: 20px;
        height: 20px;
        border-radius: 0;
        margin: 0;
        padding-right: 7px;
        margin-top: 5px;

        &:before {
            width: 20px;
            height: 20px;
            border-radius: 0;
            color: $brand-secondary;
            background-color: $brand-white;
            border: 2px solid $brand-secondary;
        }

        &:checked:before {
            content: '\2714';
            width: 20px;
            height: 20px;
            font-size: 16px;
            border-color: $brand-border;
            background-color: $brand-border;
            color: $brand-white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0;
        }
        &:checked ~ label:before {
            display: none;
        }
    }
}
