@import '../../../../styles/main.scss';

.dealer-filter-options-container {
    background: white;
    height: 100%;
    @media only screen and (max-width: 768px) {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2004;
        background-color: #fff;
        margin: 0;
        overflow: hidden;
    }
    .filters-header-container {
        display: flex;
        justify-content: space-between;
        color: #00095b;
        margin: 0 20px;
        height: 60px;

        @media only screen and (max-width: 768px) {
            margin: 0 25px;
        }
        .filters-header-reset {
            display: flex;
            flex-direction: row;
            align-items: center;
            @media only screen and (max-width: 768px) {
                display: flex;
                flex-direction: row;
            }
            .filters-header {
                font-size: 18px;
                line-height: 24px;
                font-family: $f1-font-regular;
                color: $brand-mild-gray1;
                text-transform: uppercase;
                margin-left: 5%;
                @media only screen and (max-width: 768px) {
                    font-size: 16px;
                    line-height: 1.63;
                    color: $brand-mild-gray1;
                    margin-top: 3%;
                    margin-left: 0;
                }
            }
            .filters-reset {
                margin: 30px 25% 30px;
                text-decoration: underline;
                font-family: $f1-font-regular;
                font-size: 14px;
                line-height: 1.63;
                color: #00095b;
                cursor: pointer;
                @media only screen and (max-width: 768px) {
                    margin: 6px 0 0 16px;
                    font-size: 12px;
                    line-height: 1;
                }
            }
        }
        .filter-close {
            cursor: pointer;
            margin-top: 3%;
            margin-right: 3%;
            @include mobile {
                margin-right: 0;
                margin-top: 5%;
            }
            .close-icon {
                width: 24px;
                height: 24px;
            }
        }
    }
    .accordion-filter-container {
        min-height: 83%;
        max-height: 83%;
        overflow-y: auto;
        padding-right: 30px;
        padding-left: 30px;
        @media only screen and (max-width: 768px) {
            padding: 0;
            margin: 0 15px;
        }

        .accordion-title {
            font-family: $f1-font-regular;
            font-size: 14px;
            line-height: 1.86;
            color: #00095b;
            text-transform: uppercase;
        }
    }
    .accordion-filter-container::-webkit-scrollbar {
        width: 5px;
        height: 230px;
    }

    .accordion-filter-container::-webkit-scrollbar-thumb {
        width: 5px;
        background-color: #4d4d4d;
    }
    .services-list {
        .osb-check-box-spacing {
            display: flex;
            justify-content: space-between;
            margin-left: 30px;

            .checkbox-content {
                display: flex;
                @include mobile {
                    text-align: left;
                }
                .service-description {
                    text-align: left;
                    label {
                        vertical-align: middle;
                    }
                    .checkbox-label {
                        font-size: 13px;
                    }
                }
            }
            .tooltip .tooltip__infoIcon {
                &.dark {
                    -webkit-text-fill-color: #00095b;
                    font-size: 16px;
                    background-color: $fds-color-white;
                    @include mobile {
                        font-size: 20px;
                    }
                }
            }
        }
    }
    .hr-line-style {
        border-bottom: 1px solid #6e6e6e;
        margin: 0 30px 0 0;
        @include mobile {
            margin: 0;
        }
    }
    .full-hr-line-style {
        border-bottom: 1px solid $ghost-grey;
        @include mobile {
            margin: 0;
        }
    }
    .apply-button-container {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        @media only screen and (max-width: 768px) {
            width: 100%;
            position: fixed;
            bottom: 0;
            background-color: #ffffff;
            box-shadow: 0 -10px 10px 0 rgba(201, 201, 201, 0.1),
                0 -20px 20px 0 rgba(201, 201, 201, 0.1);
        }
        .apply-button-style {
            cursor: pointer;
            display: flex;
            justify-content: center;
            margin: 18px 0 20px 0;
            @media only screen and (max-width: 768px) {
                margin: 18px 0 40px 0;
            }
        }
        .no-dealer-message {
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 1.75;
            text-align: center;
            color: #d62d0a;
            margin: 20px 0 0 0;
            @media only screen and (max-width: 768px) {
                padding: 0 25px 0 25px;
            }
        }
    }
    .accordion-filter-container {
        padding-right: 15px;
        padding-left: 15px;
        @include mobile {
            margin: 0;
            padding-right: 0;
            padding-left: 0;
        }
    }
    .services-list .osb-check-box-spacing {
        @media only screen and (max-width: 768px) {
            margin-left: 25px;
            margin-right: 25px;
            word-wrap: break-word;
        }
        .tooltip {
            @media only screen and (max-width: 768px) {
                top: -2px;
            }
        }
    }
}
