@import '../../../../styles/main.scss';

.service-price-calculator-result-container {
    width: 100%;
    padding-top: 10px;
    margin-bottom: 50px;
    background: linear-gradient(138.18deg, #ffffff 0%, #f7f7f7 100%);
    @include mobile {
        width: 98%;
    }
    .fds-activity-indicator {
        display: flex;
        flex-direction: column;
    }

    .pc-vehicle-image-container {
        display: flex;
        width: 100%;
        padding-top: 15px;
        align-items: center;
        justify-content: center;

        @include mobile {
            flex-direction: column;
        }

        .pc-vehicle-detail-image {
            width: 30%;

            @include mobile {
                height: 150px;
                justify-content: center;
                display: flex;
            }
            .pc-osb-vehicle-image {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 251px;
                height: 136px;
                margin-left: 30%;
                margin-top: -50px;
                @include mobile {
                    height: 130px;
                    width: 239px;
                    transform: scaleX(-1);
                    margin-left: 0;
                    margin-top: 0;
                }
            }
        }
        .pc-recommended-label {
            width: auto;
            min-width: 10%;
            margin: 0 1.1px 16px 1.9px;
            font-family: $f1-font-light;
            color: #4d4d4d;
            font-size: 24px;
            line-height: 34px;
            text-align: center;

            @include mobile {
                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
                opacity: 0.77;
            }
        }
        .pc-vehicle-detail-name {
            width: 30%;
            margin: 0 1.1px 16px 15px;
            font-family: $f1-font-regular;
            color: #102b4e;
            font-size: 24px;
            line-height: 34px;
            @include mobile {
                width: 100%;
                color: #102b4e;
                text-align: center;
            }
        }
    }

    .pc-map-dealer-view-container {
        display: flex;
        padding-top: 15px;
        font-family: $f1-font-regular;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        color: #4d4d4d;
        height: 760px;
        @include mobile {
            flex-direction: column;
            height: auto;
        }
        .pc-map {
            width: 100%;
            height: 760px;

            @include mobile {
                height: 218px;
            }

            .osb-map-container {
                height: 100%;
            }
        }
        .pc-filter-dealer-result-container {
            width: 100%;
            margin: 0 1.1px 16px 1.9px;
            height: 760px;
            @include mobile {
                height: auto;
            }

            .pc-filter {
                width: 100%;
                height: 60px;
                color: #102b4e;
                font-family: $f1-font-light;
                font-size: 18px;
                line-height: 13px;
                text-align: center;
                background: linear-gradient(180deg, #ffffff 0%, #d8d8d8 100%);
                display: flex;
                align-items: center;
                justify-content: center;

                .dealer-filter {
                    display: contents;
                    cursor: pointer;
                }
            }

            .pc-dealer-list {
                width: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                font-family: $f1-font-light;
                font-size: 20px;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                height: 700px;
                background: linear-gradient(
                    138.18deg,
                    #ffffff 0%,
                    #f7f7f7 100%
                );
                @include mobile {
                    height: auto;
                }

                .dealer-items-container {
                    .osb-loader-message {
                        width: 100%;
                    }

                    display: grid;
                    grid-template-columns: 8% 58% 35%;
                    margin: 26px 0 0 0;
                    @include tablet-portrait {
                        grid-template-columns: 10% 90%;
                        padding: 0 10% 0 10%;
                        margin: 25px 0 0 0;
                    }
                    .sequence-number {
                        font-family: $f1-font-regular;
                        font-size: 48px;
                        color: #102b4e;
                        line-height: 58px;
                        text-align: right;
                        margin-right: 20%;
                        @include tablet-portrait {
                            grid-column: 1 / 2;
                            grid-row: 1 / 2;
                            font-size: 34px;
                            line-height: 1.29;
                            text-align: center;
                        }
                        @media only screen and (max-width: 320px) {
                            font-size: 28px;
                        }
                    }
                    .dealer-info {
                        @include tablet-portrait {
                            grid-column: 2 / 3;
                            grid-row: 1 / 2;
                            margin-left: 8px;
                            margin-bottom: 5%;
                        }
                        @media only screen and (min-width: 768px) and (max-width: 1024px) {
                            margin-left: 15px;
                        }
                        .dealer-name {
                            font-family: $f1-font-regular;
                            font-size: 16px;
                            line-height: 26px;
                            color: #102b4e;
                            text-transform: uppercase;
                            padding-top: 6px;
                            @include tablet-portrait {
                                padding-top: 3px;
                                font-weight: 500;
                            }
                        }
                        .dealer-address {
                            display: flex;
                            margin: 8px 0 0 0;
                            font-family: $f1-font-regular;
                            font-size: 16px;
                            line-height: 1.63;
                            color: #4d4d4d;
                            @include tablet-portrait {
                                font-size: 12px;
                                line-height: 1.75;
                            }
                        }
                        .distance-miles {
                            display: flex;
                            margin: 16px 0 0 0;
                            font-family: $f1-font-regular;
                            font-size: 12px;
                            line-height: 1;
                            color: #4d4d4d;
                            @include tablet-portrait {
                                font-size: 11px;
                                line-height: 1.91;
                                margin: 8px 0 0 0;
                            }
                            .miles-label {
                                margin-left: 3px;
                            }
                        }
                    }
                    .select-button-container {
                        display: flex;
                        align-items: start;
                        padding-top: 10px;
                        justify-content: center;
                        margin-right: 10%;
                        @include mobile {
                            margin-right: 0;
                            justify-content: center;
                            padding-top: 0;
                        }

                        .primary-button {
                            max-width: 185px;
                            justify-content: center;
                            padding: 0 15px;
                            .button-label.no-chevron {
                                white-space: break-spaces;
                                margin-top: 5%;
                                margin-bottom: 5%;
                            }
                        }
                        .button-label {
                            font-size: 18px;
                            font-family: $f1-font-regular;
                            line-height: 1.25rem;
                            text-align: center;
                            color: #102b4e;
                        }
                        @include tablet-portrait {
                            grid-column: 2 / 3;
                            grid-row: 2 / 3;
                            margin: 15px 0 0 0;
                        }
                    }
                    .hr-line-style {
                        grid-column: 1 / 4;
                        box-sizing: border-box;
                        height: 1px;
                        width: 90%;
                        border: 0.5px solid #919191;
                        margin-right: 15%;
                        margin-left: 4%;
                        margin-top: 2.5%;
                        @include tablet-portrait {
                            margin: 39px 0 0 0;
                            width: 100%;
                        }
                    }
                }

                .record-count {
                    display: flex;
                    justify-content: center;
                    margin: 10px 36px 0 0;
                    @include mobile {
                        margin: 5% 0 0 0;
                    }
                }

                .view-more-button-container {
                    display: flex;
                    justify-content: center;
                    margin: 10px 36px 0 0;
                    .view-more {
                        margin-top: 15px;
                        color: #00095b;
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                        .fds-chevron-wrap {
                            display: block;
                            margin-left: 5px;
                        }
                    }
                    @include tablet-portrait {
                        margin: 20px 0 20px 0;
                    }
                }
                .empty-div {
                    height: 600px;
                    @include mobile {
                        height: 0;
                    }
                }
            }
        }
    }
    .mobile-filters {
        cursor: pointer;
        display: flex;
        @media only screen and (max-width: 768px) {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 59px;
            position: fixed;
            top: 60px;
            width: 100%;
            cursor: pointer;
            z-index: 2004;
            overflow-y: hidden;
            border-top: 0.2px solid #a7a7a7;
            background: linear-gradient(180deg, #ffffff 0%, #d8d8d8 100%);
        }
        .filter-icon {
            width: 20px;
            height: 19px;
            object-fit: contain;
            margin: 0;
            @media only screen and (max-width: 768px) {
                width: 24px;
                height: 23px;
            }
        }
        .filter-label {
            text-transform: capitalize;
            font-size: 18px;
            font-family: $f1-font-regular;
            color: #00095b;
            line-height: 0.72;
            margin: 0 0 0 5px;
        }
    }
}
