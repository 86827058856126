@import '../../../../styles/main.scss';
.pc-input-label {
    font-family: $f1-font-regular;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 6px;
    height: 18px;
    color: #4d4d4d;
    @include mobile {
        height: 10px;
    }
}

.pc-input-error {
    width: 270px;
    font-size: 12px;
    color: #d62d0a;
    font-family: $f1-font-regular;
    line-height: 16px;
    padding-top: 8px;
    @media screen and (max-width: 300px) {
        width: 180px;
    }
}

.pc-input-text-error {
    border: solid 1px #d62d0a;
}

.osb-error-icon {
    width: 18px;
    height: 18px;
    margin: 0 0 0 8px;
}
