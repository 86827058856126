@import '../../../../styles/main.scss';
.spc-dealer-details-mobile-map-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .osb-map-container {
        position: relative;
        height: 60vh;
        z-index: 0;
    }
    .mobile-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding-left: 10px;
        .dealer-tiles-container {
            display: flex;
            overflow: auto;
            scroll-snap-type: x mandatory;
            transform: translateY(-35%);
            left: 10px;
            width: 100%;
            z-index: 0;
            .dealer-info-box-wrapper {
                position: relative;
                border-radius: 10px;
                width: 90%;
                scroll-snap-align: start;
                margin-right: 1rem;
                z-index: 1;
                display: grid;
                grid-gap: 22.5px;

                .preferred-dealer-label {
                    position: absolute;
                    margin: 0 auto;
                    z-index: 1;
                    grid-row: 1 / 2;
                    color: $fds-color-white;
                    background-color: $fds-color-secondary;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                    font-family: $f1-font-regular;
                    font-size: 11px;
                    letter-spacing: 1px;
                    line-height: 17px;
                    width: fit-content;
                    padding: 5px 10px 0;
                }
                .map-view-dealer-detail-section {
                    grid-row: 2 / 2;
                    background-color: $fds-color-white;
                    //padding: 15px;
                    border-radius: 3px;
                    height: 327px;
                    width: 310px;
                    overflow-y: auto;
                    /* Customize the scrollbar */
                    &::-webkit-scrollbar {
                        width: 3px;
                        height: 5px;
                    }

                    &::-webkit-scrollbar-track {
                        background-color: $fds-color-lt-gray;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: $quicklink-textactive;
                        border-radius: 10px;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        background-color: $quicklink-textactive;
                    }
                }
                .dealer-details-section {
                    .dealer-info {
                        padding: 15px;
                        .dealer-name {
                            color: #00095b;
                            font-family: $f1-font-regular;
                            font-size: 16px;
                            font-weight: bold;
                            letter-spacing: 2px;
                            line-height: 26px;
                            text-transform: uppercase;
                        }
                        .dealer-address-container {
                            .dealer-tile-address-text {
                                color: #4d4d4d;
                                font-family: $f1-font-regular;
                                font-size: 12px;
                                letter-spacing: 1.09px;
                                line-height: 21px;
                                margin: 5px 0;
                            }
                            .dealer-distance {
                                color: #4d4d4d;
                                font-family: $f1-font-regular;
                                font-size: 12px;
                                letter-spacing: 1.09px;
                                line-height: 21px;
                            }
                        }
                    }
                }
                .dealer-service-section {
                    max-height: 170px;
                    min-height: 140px;
                    //margin: 20px 0;
                    padding: 10px 15px;
                    overflow-y: auto;
                    -webkit-box-shadow: 0 8px 6px -6px $fds-color-surface;
                    -moz-box-shadow: 0 8px 6px -6px $fds-color-surface;
                    box-shadow: 0 8px 6px -6px $fds-color-surface;
                    /* Customize the scrollbar */
                    &::-webkit-scrollbar {
                        width: 3px;
                        height: 5px;
                    }

                    &::-webkit-scrollbar-track {
                        background-color: $fds-color-surface;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: $fds-color-surface;
                        border-radius: 10px;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        background-color: $fds-color-surface;
                    }
                    .dealer-service-container {
                        margin: 10px 0;
                        .service-separator {
                            display: none;
                        }
                        .dealer-service-info {
                            display: flex;
                            .service-name {
                                color: #00095b;
                                font-family: $f1-font-regular;
                                font-size: 12px;
                                font-weight: bold;
                                letter-spacing: 2px;
                                line-height: 18px;
                                width: 60%;
                                text-transform: uppercase;
                                span {
                                    font-size: 20px;
                                    margin-left: 0px;
                                }
                            }
                            .service-price {
                                color: #00095b;
                                font-family: $f1-font-regular;
                                font-size: 12px;
                                letter-spacing: 2.35px;
                                line-height: 14.4px;
                                text-align: right;
                                width: 40%;
                            }
                        }
                    }
                    .dealer-convenience-option {
                        .fmc-accordion {
                            .fmc-accordion__panel {
                                .fmc-accordion__button {
                                    height: 30px;
                                    padding: 5px 10px;
                                    .fmc-accordion__button-expand {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }

                .dealer-cta-container {
                    // margin: 10px 0;
                    padding: 15px;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
    .view-more-container-mobile {
        align-self: center;
        display: flex;
        align-items: center;
        .view-more-button-mobile {
            position: absolute;
            bottom: 29px;
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 10px;
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;
            .view-more-text-mobile {
                color: $fds-color-primary;
                font-family: $f1-font-regular;
                font-size: 18px;
                letter-spacing: 1px;
                line-height: 24px;
                padding-right: 15px;
            }
            .fds-chevron.down {
                color: $fds-color-primary;
            }
        }
    }
    .search-list-container {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 460px;
        height: 100%;
        background-color: transparent;
        z-index: 1;
        overflow-y: auto;
        margin-right: 20px;
        .filter-container {
            margin-top: 20px;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
        }

        .dealer-info-box:first-of-type {
            margin-top: 20px;
        }
    }
    .map-actions {
        position: absolute;
        top: 40px;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        padding: 15px;
        &.fixed {
            position: fixed;
            top: 85px;
        }
        .filter-link,
        .list-link {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $fds-color-white;
            color: $fds-color-primary;
            border-radius: 20px;
            padding: 10px;
            font-size: 16px;
            cursor: pointer;
            margin-right: 14px;
            margin-bottom: 30px;
            width: 40%;
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2),
                0px 0px 6px rgba(0, 0, 0, 0.2);
            .filters-text,
            .list-text {
                margin-left: 10px;
                color: $fds-color-primary;
                font-family: $f1-font-regular;
                font-size: 18px;
                letter-spacing: 1px;
            }
        }
    }
}
